import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { isEmpty } from 'lodash'

import {
  Container,
  ContentHeader,
  DialogLogs,
  LoadingFeedback,
  MenuButton,
  Page,
} from 'components'
import { RegisterForm, RegisterTypeForm } from './components/partner/new'
import { SkeletonForm } from './components/partner/edit'

import useFetch from 'hooks/useFetch'
import useConvertToFile from 'hooks/useConvertToFile'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const ThirdPartyManagementPartnerEdit = ({ match }) => {
  const { supplierInviteId } = match.params
  const registerTypes = constants.thirdPartyManagements.REGISTER_TYPES

  const [openLogDialog, setOpenLogDialog] = useState(false)
  const [registerType, setRegisterType] = useState(registerTypes.REGISTER)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.supplierInvite.get,
    {
      supplierInviteId: supplierInviteId,
    },
  )

  const { response: responseLogs, isLoading: isLoadingLogs } = useFetch(
    service.dponet.supplierInvite.logs,
    {
      supplierInviteId: supplierInviteId,
    },
    [openLogDialog],
    openLogDialog,
  )

  const logs = helpers.logs.changeLogs(responseLogs?.data?.dponetAudits || [])
  const supplierInvite = response?.data?.supplierInvite || {}

  const { file, loading } = useConvertToFile(
    supplierInvite?.companySupplierDetail?.attachment,
  )

  useEffect(() => {
    if (supplierInvite) {
      if (
        supplierInvite?.preAvaliation &&
        registerType !== registerTypes.PRE_AVALIATION
      ) {
        setRegisterType(registerTypes.PRE_AVALIATION)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierInvite])

  return (
    <Page title="Gestão de Terceiros - Atualizar Parceiro">
      <Container maxWidth={false}>
        <ContentHeader title="Atualizar Parceiro" breakLine={false}>
          <MenuButton>
            <Button
              color="secondary"
              onClick={() => setOpenLogDialog(true)}
              fullWidth
            >
              Histórico
            </Button>
          </MenuButton>
        </ContentHeader>

        {isLoading || loading ? (
          <SkeletonForm />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RegisterTypeForm
                registerType={registerType}
                setRegisterType={setRegisterType}
                isEdit
              />
            </Grid>
            <Grid item xs={12}>
              <RegisterForm
                registerType={registerType}
                isEdit
                supplierInvite={supplierInvite}
                supplierFile={file}
                questionnaires={
                  supplierInvite?.questionnairesParams?.questionnaireIds
                }
                reload={refresh}
              />
            </Grid>
          </Grid>
        )}
      </Container>

      <LoadingFeedback open={isLoadingLogs} />

      <DialogLogs
        logs={isEmpty(logs) ? undefined : logs}
        open={openLogDialog}
        setOpen={setOpenLogDialog}
        showRegisterSession={false}
      />
    </Page>
  )
}

export default ThirdPartyManagementPartnerEdit
