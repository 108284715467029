import React from 'react'
import { Grid, Box, Card, Typography, Chip, Divider } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from '../../../QuestionTable/styles'

const Info = ({ questionControl }) => {
  const classes = useStyles()
  const disableAlert = constants.questionControl.ENABLE_ALERT.includes(
    questionControl?.status,
  )

  return (
    <Box>
      <Grid container spacing={3}>
        {disableAlert && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="info">
              <Typography>
                {helpers.questionControls.alertInfo(questionControl?.status)}
              </Typography>
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
              mb={2}
            >
              <Typography variant="h4">
                #{questionControl?.id} {questionControl?.title}
              </Typography>
              <Chip
                size="small"
                className={helpers.questionControls.statusChipColor(
                  questionControl?.status,
                  classes,
                )}
                label={helpers.questionControls.status(questionControl?.status)}
              />
            </Box>
            <Divider />
            <Box p={2}>
              <Typography variant="subtitle1" color="secondary">
                {questionControl?.subtitle}
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Info
