import permissionsConstants from 'constants/permissions'

const LAST_ID_BEFORE_UPDATE_DESCRIPTION = 3594

const PENDING_STATUS = 1
const WAITING_REVIEW_STATUS = 2
const DISAPPROVED_STATUS = 3
const APPROVED_STATUS = 4
const REVIEWING = 5
const INACTIVE = 6
const SUGGESTED = 7
const DISCARDED = 8
const PENDING_LIA = 10
const REVIEW_LIA_CLIENT = 11
const REVIEW_LIA_DPO = 12
const DISAPPROVED_LIA = 13

const REVIEW_LIA_STATUSES = [REVIEW_LIA_CLIENT, REVIEW_LIA_DPO]

const PERMITTED_STATUS_SHOW_LIA = [
  APPROVED_STATUS,
  PENDING_LIA,
  REVIEW_LIA_CLIENT,
  REVIEW_LIA_DPO,
]

const PHYSIC_PROTECTION = 'Físico'

const SUGGESTED_PENDING_STATUS = 1
const SUGGESTED_APPROVING_STATUS = 2
const SUGGESTED_REFUSE = 3

const TITULAR_DATA_SOURCE = 1
const DEPARTMENT_SOURCE = 2
const THIRD_PARTY_SOURCE = 3
const PUBLIC_SOURCE = 4
const ANOTHER_SOURCE = 5

const GENERAL_INFORMATION_STEP = 0
const DATA_COLLECTED_STEP = 1
const DATA_LIFE_CICLE_STEP = 2
const DATA_TREATMENT_STEP = 3
const DATA_TREATMENT_AGENT_STEP = 4
const FRAGILITY_ENTITIES_STEP = 5
const NECESSITY_PROPORTIONALITY_STEP = 6
const DOCUMENT_STEP = 7
const REVISION_STEP = 8

const PERMITTED_DOCUMENTS_TYPE =
  '.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xlsx,.csv'

const SOURCE_DESCRIPTION_FIELD = 'sourceDescription'
const DATA_PROCESS_SOURCES_FIELD = 'dataProcessSources'

const TITULAR_DESCRIPTION =
  'O próprio titular fornece os dados (diretamente para a organização).'
const DEPARTMENT_DESCRIPTION =
  'Outro departamento compartilha os dados com este departamento.'
const THIRD_PARTY_SOURCE_DESCRIPTION =
  'Um terceiro (de fora da organização) compartilha os dados com a organização.'
const PUBLIC_SOURCE_DESCRIPTION = 'Os dados são públicos.'

const SOURCE_DEPARTMENT_KIND = 'Department'
const SOURCE_THIRD_PARTY_KIND = 'ThirdParty'
const SOURCE_PUBLIC_KIND = 'Public'

const CARD_ORIGIN_DATA_PROCESS_SUGGESTION = 'DataProcessSuggestion'
const CARD_ORIGIN_DISCARDED = 'DataProcessDiscarded'
const CARD_ORIGIN_SUGGESTED_CHANGES = 'SuggestedChanges'
const LIST_IN_CARD_TYPE = 'cards'
const LIST_IN_TABLE_TYPE = 'table'

const FRAGILITY_FILTER_OPTIONS = [
  { id: '1', name: 'Baixo' },
  { id: '2', name: 'Médio' },
  { id: '3', name: 'Alto' },
  { id: '4', name: 'Severo' },
]

const DATA_TYPE_OPTIONS = [
  { id: '1', name: 'Sensíveis' },
  { id: ['2', '5'], name: 'Pessoais' },
  { id: ['3', '6'], name: 'Financeiros' },
  { id: ['4', '7'], name: 'Comportamental' },
]

const SHARE_TYPE_OPTIONS = [
  { id: '1', name: 'Interno' },
  { id: '2', name: 'Externo' },
  { id: '3', name: 'Interno e Externo' },
]

const SOURCES_WITH_ENTITY = [
  DEPARTMENT_SOURCE,
  THIRD_PARTY_SOURCE,
  PUBLIC_SOURCE,
  DEPARTMENT_DESCRIPTION,
  THIRD_PARTY_SOURCE_DESCRIPTION,
  PUBLIC_SOURCE_DESCRIPTION,
]

const ALL_PROCESSES_VALUE = 'ALL_PROCESSES'
const AUDIT_PROCESSES_VALUE = 'AUDIT_PROCESSES'

const ALL_PROCESSES_TAB = {
  value: ALL_PROCESSES_VALUE,
  label: 'Todos os processos',
}

const AUDIT_PROCESSES_TAB = {
  value: AUDIT_PROCESSES_VALUE,
  label: 'Em auditoria',
}

const TABS = [AUDIT_PROCESSES_TAB, ALL_PROCESSES_TAB]

const STORY_MODE_UNDEFINED_TYPE = 'undefined_type'
const STORY_MODE_DEFINED_TYPE = 'defined_type'
const STORY_MODE_PERMANENT_TYPE = 'permanent_type'

const STORAGE_DEFINED_TYPE = 1
const STORAGE_UNDEFINED_TYPE = 2
const STORAGE_PERMANENT_TYPE = 3

const DATA_PROCESS_MAIN_DRIVER_STEP_1 = 'data-process-main-driver-step-1'
const DATA_PROCESS_MAIN_DRIVER_STEP_2 = 'data-process-main-driver-step-2'
const DATA_PROCESS_MAIN_DRIVER_STEP_3 = 'data-process-main-driver-step-3'
const DATA_PROCESS_MAIN_DRIVER_STEP_4 = 'data-process-main-driver-step-4'
const DATA_PROCESS_MAIN_DRIVER_STEP_5 = 'data-process-main-driver-step-5'
const DATA_PROCESS_MAIN_DRIVER_STEP_6 = 'data-process-main-driver-step-6'
const DATA_PROCESS_MAIN_DRIVER_STEP_7 = 'data-process-main-driver-step-7'
const DATA_PROCESS_MAIN_DRIVER_STEP_8 = 'data-process-main-driver-step-8'
const DATA_PROCESS_MAIN_DRIVER_STEP_9 = 'data-process-main-driver-step-9'

const DATA_PROCESS_MAIN_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Nesta etapa, você irá identificar e mapear os dados pessoais coletados em cada atividade realizada dentro da sua organização. Isso envolve um processo detalhado de compreensão de todas as fontes de dados, desde formulários online até registros de clientes, e categorizá-los de acordo com sua natureza.',
    },
  },
  {
    element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Crie um novo processo. O processo deve ser cadastrado para todas as atividades que utilizam dados pessoais passiveis de tratamento. Exemplos: Cadastro de clientes, folha de pagamento, emissão de contrato.',
    },
  },
  {
    element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_3,
    popover: {
      description:
        'No caso do primeiro acesso, é crucial revisar todos os processos aprovados pelo sistema, a fim de determinar sua real adequação à realidade da organização.',
    },
  },
  {
    element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_4,
    popover: {
      description:
        'Veja quais são os processos sem tempo definido de armazenamento de dados aqui.',
    },
  },
  {
    element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_5,
    popover: {
      description:
        'Veja quais são os processos em que é necessária uma análise da necessidade e proporcionalidade dos dados coletados. A análise é necessária para classificar e descartar os dados considerados desnecessários.',
    },
  },
]

const DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_6 = {
  element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_6,
  popover: {
    title: 'Status Aprovado',
    description:
      'Reabra, inative ou visualize o processo, conforme necessário. Ainda, você pode copiar o processo para outras empresas sob sua responsabilidade.',
  },
}

const DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_7 = {
  element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_7,
  popover: {
    title: 'Status Pendente',
    description:
      'O Processo está aguardando uma nova edição ou ser enviado para revisão.',
  },
}

const DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_8 = {
  element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_8,
  popover: {
    title: 'Status Aguardando Revisão',
    description:
      'O processo foi enviado para revisão, e de acordo com a análise do profissional DPO, e poderá ser aprovado ou reprovado. Caso o DPO reprove seu processo, você terá que fazer os ajustes necessários, de acordo com a orientação.',
  },
}

const DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_9 = {
  element: '#' + DATA_PROCESS_MAIN_DRIVER_STEP_9,
  popover: {
    title: 'Status Reprovado',
    description:
      'Após a análise do processo, este status inclui uma observação da equipe de compliance. Por favor, reabra o processo, efetue as alterações necessárias e reenvie-o para revisão.',
  },
}

const DATA_PROCESS_MAIN_DRIVER_STEPS_WITH_PERMISSIONS = {
  ['#' + DATA_PROCESS_MAIN_DRIVER_STEP_2]:
    permissionsConstants.DATA_PROCESSES.CREATE,
}

const DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_1 =
  'data-process-suggested-main-driver-step-1'
const DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_2 =
  'data-process-suggested-main-driver-step-2'

const DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Sugerimos <strong>Alterações</strong> para a gestão e atualização contínua dos mapeamentos. Alterações podem ser sugeridas por diversos motivos, orientações da ANPD, alterações em leis trabalhistas ou do consumidor, novos estudos que indicam melhores práticas. O objetivo é garantir que seus processos estejam alinhados com as melhores práticas e regulamentações em constante evolução.',
    },
  },
  {
    element: '#' + DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Nas sugestões da plataforma, você tem as escolhas de "Adicionar" ou "Não adicionar", "Atualizar" ou "Não atualizar", e "Remover" ou "Não remover". No contexto Matriz e filiais, se a Matriz aceitar a sugestão, ela será transmitida para as filiais. Caso a Matriz não aceite a sugestão, as filiais não a receberão.',
    },
  },
]

const SUGGESTED_OR_MAIN_DRIVER_ID = {
  suggested: {
    tourId: DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_1,
    tabId: DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_2,
  },
  main: {
    tourId: DATA_PROCESS_MAIN_DRIVER_STEP_1,
    tabId: DATA_PROCESS_MAIN_DRIVER_STEP_3,
  },
}

const QUESTION_DRIVER_STATUS_ID = {
  [APPROVED_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_6,
  [PENDING_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_7,
  [REVIEWING]: DATA_PROCESS_MAIN_DRIVER_STEP_8,
  [WAITING_REVIEW_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_8,
  [DISAPPROVED_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_9,
}

const DATA_PROCESS_DRIVER_SELECTED_STEPS = {
  [APPROVED_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_6,
  [PENDING_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_7,
  [REVIEWING]: DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_8,
  [WAITING_REVIEW_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_8,
  [DISAPPROVED_STATUS]: DATA_PROCESS_MAIN_DRIVER_STEP_OBJECT_9,
}

const DATA_PROCESS_EDIT_DRIVER_STEP_1 = 'data-process-edit-driver-step-1'
const DATA_PROCESS_EDIT_DRIVER_STEP_2 = 'data-process-edit-driver-step-2'
const DATA_PROCESS_EDIT_DRIVER_STEP_3 = 'data-process-edit-driver-step-3'
const DATA_PROCESS_EDIT_DRIVER_STEP_4 = 'data-process-edit-driver-step-4'
const DATA_PROCESS_EDIT_DRIVER_STEP_5 = 'data-process-edit-driver-step-5'
const DATA_PROCESS_EDIT_DRIVER_STEP_6 = 'data-process-edit-driver-step-6'
const DATA_PROCESS_EDIT_DRIVER_STEP_7 = 'data-process-edit-driver-step-7'
const DATA_PROCESS_EDIT_DRIVER_STEP_8 = 'data-process-edit-driver-step-8'
const DATA_PROCESS_EDIT_DRIVER_STEP_9 = 'data-process-edit-driver-step-9'

const DATA_PROCESS_EDIT_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_1,
    popover: {
      description: 'Registre o nome do processo e vincule-o a um departamento.',
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_2,
    popover: {
      description:
        'Descreva a origem dos dados e o motivo pelo qual estão sendo tratados.',
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_3,
    popover: {
      description:
        "Defina um tempo de armazenamento. As organizações devem determinar um período específico para a retenção de dados pessoais, exceto em casos previstos por lei, os quais serão designados como 'Permanente'. A opção 'Indefinido' destina-se exclusivamente ao salvamento do progresso do processo.",
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_4,
    popover: {
      description:
        'Cadastre quais dados são tratados, definindo a categoria do titular, faixa etária, quantidade aproximada de titulares e quais dados estão sendo tratados.',
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_5,
    popover: {
      description:
        'Inclua informações sobre com quem os dados são compartilhados. Defina por que, como e qual o tipo de compartilhamento, e informe se trata-se de um compartilhamento internacional.',
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_6,
    popover: {
      description:
        'Defina o <strong>Controlador</strong> e o <strong>Operador</strong> do processo. O controlador decide como e por que os dados pessoais são tratados, enquanto o operador realiza o tratamento conforme as instruções do controlador.',
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_7,
    popover: {
      description:
        'Adicione a salvaguarda e ciclo de vida, registre onde os dados estão armazenados, por quanto tempo serão armazenados, justifique a retenção, como serão dispostos, a forma de recuperação e a forma de proteção desses dados.',
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_8,
    popover: {
      description:
        'Adicione uma <strong>ameaça</strong>. Caso a ameaça se aplique a todos os processos da empresa ou departamento o risco é aplicado automaticamente. Adicione ou exclua os riscos.',
    },
  },
  {
    element: '#' + DATA_PROCESS_EDIT_DRIVER_STEP_9,
    popover: {
      description: 'Envie o processo para revisão.',
    },
  },
]

const DATA_PROCESS_NEW_DRIVER_STEP_1 = 'data-process-new-driver-step-1'
const DATA_PROCESS_NEW_DRIVER_STEP_2 = 'data-process-new-driver-step-2'
const DATA_PROCESS_NEW_DRIVER_STEP_3 = 'data-process-new-driver-step-3'
const DATA_PROCESS_NEW_DRIVER_STEP_4 = 'data-process-new-driver-step-4'
const DATA_PROCESS_NEW_DRIVER_STEP_5 = 'data-process-new-driver-step-5'
const DATA_PROCESS_NEW_DRIVER_STEP_6 = 'data-process-new-driver-step-6'
const DATA_PROCESS_NEW_DRIVER_STEP_7 = 'data-process-new-driver-step-7'
const DATA_PROCESS_NEW_DRIVER_STEP_8 = 'data-process-new-driver-step-8'
const DATA_PROCESS_NEW_DRIVER_STEP_9 = 'data-process-new-driver-step-9'
const DATA_PROCESS_NEW_DRIVER_STEP_10 = 'data-process-new-driver-step-10'
const DATA_PROCESS_NEW_DRIVER_STEP_11 = 'data-process-new-driver-step-11'

const GENERAL_INFORMATION_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_1,
    popover: {
      description: 'Registre o nome do processo e vincule-o a um departamento.',
    },
  },
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_2,
    popover: {
      description:
        'Descreva a origem dos dados e o motivo pelo qual estão sendo tratados.',
    },
  },
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_3,
    popover: {
      description:
        "Defina um tempo de armazenamento. As organizações devem determinar um período específico para a retenção de dados pessoais, exceto em casos previstos por lei, os quais serão designados como 'Permanente'. A opção 'Indefinido' destina-se exclusivamente ao salvamento do progresso do processo.",
    },
  },
]

const PROCESSED_DATA_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_4,
    popover: {
      description:
        'Cadastre quais dados são tratados, definindo a categoria do titular, faixa etária, quantidade aproximada de titulares e quais dados estão sendo tratados.',
    },
  },
]

const SAFEGUARD_AND_LIFECYCLE_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_5,
    popover: {
      description:
        'Adicione a salvaguarda e ciclo de vida, registre onde os dados estão armazenados, por quanto tempo serão armazenados, justifique a retenção, como serão dispostos, a forma de recuperação e a forma de proteção desses dados.',
    },
  },
]

const SHARED_DATA_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_6,
    popover: {
      description:
        'Inclua informações sobre com quem os dados são compartilhados. Defina por que, como e qual o tipo de compartilhamento, e informe se trata-se de um compartilhamento internacional.',
    },
  },
]

const TREATMENT_AGENTS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_7,
    popover: {
      description:
        'Defina o <strong>Controlador</strong> e o <strong>Operador</strong> do processo. O controlador decide como e por que os dados pessoais são tratados, enquanto o operador realiza o tratamento conforme as instruções do controlador.',
    },
  },
]

const RISK_ANALYSIS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_8,
    popover: {
      description:
        'Adicione uma <strong>ameaça.</strong> Caso a ameaça se aplique a todos os processos da empresa ou departamento o risco é aplicado automaticamente. Adicione ou exclua os riscos.',
    },
  },
]

const NECESSITY_AND_PROPORTIONALITY_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_9,
    popover: {
      description:
        'Indique possíveis alterações nos dados tratados, na forma de proteção e no compartilhamento, considerando se são necessárias e proporcionais ou não.',
    },
  },
]

const DOCUMENTED_INFORMATION_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_10,
    popover: {
      description:
        'Anexe o modelo do documento referente à atividade de tratamento. <strong>Não anexe documentos com dados pessoais.</strong>',
    },
  },
]

const REVISION_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_NEW_DRIVER_STEP_11,
    popover: {
      description:
        'Revise todos os campos preenchidos para confirmar que as informações correspondem à realidade do tratamento de dados.',
    },
  },
]

const CHOOSE_WHICH_NEW_DRIVER_STEP_OBJECT = {
  0: GENERAL_INFORMATION_DRIVER_STEPS_OBJECT,
  1: PROCESSED_DATA_DRIVER_STEPS_OBJECT,
  2: SAFEGUARD_AND_LIFECYCLE_DRIVER_STEPS_OBJECT,
  3: SHARED_DATA_DRIVER_STEPS_OBJECT,
  4: TREATMENT_AGENTS_DRIVER_STEPS_OBJECT,
  5: RISK_ANALYSIS_DRIVER_STEPS_OBJECT,
  6: NECESSITY_AND_PROPORTIONALITY_DRIVER_STEPS_OBJECT,
  7: DOCUMENTED_INFORMATION_DRIVER_STEPS_OBJECT,
  8: REVISION_DRIVER_STEPS_OBJECT,
}

const DATA_PROCESS_SHOW_DRIVER_STEP_1 = 'data-process-show-driver-step-1'
const DATA_PROCESS_SHOW_DRIVER_STEP_2 = 'data-process-show-driver-step-2'

const DATA_PROCESS_SHOW_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_SHOW_DRIVER_STEP_1,
    popover: {
      description:
        'Realize a edição do processo, consulte o histórico de alterações ou faça a exclusão.',
    },
  },
  {
    element: '#' + DATA_PROCESS_SHOW_DRIVER_STEP_2,
    popover: {
      description: 'Envie o processo para revisão.',
    },
  },
]

const DATA_PROCESS_RESUME_DRIVER_STEP_1 = 'data-process-resume-driver-step-1'
const DATA_PROCESS_RESUME_DRIVER_STEP_2 = 'data-process-resume-driver-step-2'
const DATA_PROCESS_RESUME_DRIVER_STEP_3 = 'data-process-resume-driver-step-3'

const DATA_PROCESS_RESUME_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DATA_PROCESS_RESUME_DRIVER_STEP_1,
    popover: {
      description:
        'O resumo proporciona uma visão geral, mostrando quantos processos cada departamento tem e o status deles. Utilize esta seção como referência para ter uma compreensão rápida da distribuição dos processos e de seu estado atual.',
    },
  },
  {
    element: '#' + DATA_PROCESS_RESUME_DRIVER_STEP_2,
    popover: {
      description:
        'Crie um novo processo. O processo deve ser cadastrado para todas as atividades que utilizam dados pessoais passiveis de tratamento. Exemplos: Cadastro de clientes, folha de pagamento, emissão de contrato.',
    },
  },
  {
    element: '#' + DATA_PROCESS_RESUME_DRIVER_STEP_3,
    popover: {
      description:
        'É possível clicar em qualquer status para verificar de qual ou quais processos referem-se. Caso clicar em "total", todos os processos desse departamento serão exibidos.',
    },
  },
]

const DATA_PROCESS_SUGGESTED_DRIVER_STEP_1 =
  'data-process-suggested-driver-step-1'
const DATA_PROCESS_SUGGESTED_DRIVER_STEP_2 =
  'data-process-suggested-driver-step-2'
const DATA_PROCESS_SUGGESTED_DRIVER_STEP_3 =
  'data-process-suggested-driver-step-3'
const DATA_PROCESS_SUGGESTED_DRIVER_STEP_4 =
  'data-process-suggested-driver-step-4'
const DATA_PROCESS_SUGGESTED_DRIVER_STEP_5 =
  'data-process-suggested-driver-step-5'

const DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_1 = [
  {
    element: '#' + DATA_PROCESS_SUGGESTED_DRIVER_STEP_1,
    popover: {
      description:
        'Sugerimos Novos processos para a gestão e atualização contínua dos mapeamentos. Novos processos podem ser sugeridos por diretrizes da ANPD, mudanças nas leis trabalhistas ou do consumidor, novos estudos que destacam melhores práticas, visando assegurar que seus processos estejam sempre alinhados com as regulamentações em constante evolução, ou no caso de filiais que  novos processos foram criados nas matrizes. O objetivo é garantir que seus processos estejam alinhados com as melhores práticas e regulamentações.',
    },
  },
  {
    element: '#' + DATA_PROCESS_SUGGESTED_DRIVER_STEP_2,
    popover: {
      description: 'Encontre os novos processos sugeridos.',
    },
  },
  {
    element: '#' + DATA_PROCESS_SUGGESTED_DRIVER_STEP_3,
    popover: {
      description: 'Verifique aqui os processos que foram descartados.',
    },
  },
]

const DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_2 = {
  element: '#' + DATA_PROCESS_SUGGESTED_DRIVER_STEP_4,
  popover: {
    description: 'Aceite ou exclua os processos sugeridos.',
  },
}

const DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_3 = {
  element: '#' + DATA_PROCESS_SUGGESTED_DRIVER_STEP_5,
  popover: {
    description: 'É possível aceitar os processos descartados.',
  },
}

export default {
  ALL_PROCESSES_TAB,
  ALL_PROCESSES_VALUE,
  ANOTHER_SOURCE,
  APPROVED_STATUS,
  AUDIT_PROCESSES_TAB,
  AUDIT_PROCESSES_VALUE,
  CARD_ORIGIN_DATA_PROCESS_SUGGESTION,
  CARD_ORIGIN_DISCARDED,
  CARD_ORIGIN_SUGGESTED_CHANGES,
  CHOOSE_WHICH_NEW_DRIVER_STEP_OBJECT,
  DATA_COLLECTED_STEP,
  DATA_LIFE_CICLE_STEP,
  DATA_PROCESS_DRIVER_SELECTED_STEPS,
  DATA_PROCESS_EDIT_DRIVER_STEP_1,
  DATA_PROCESS_EDIT_DRIVER_STEP_2,
  DATA_PROCESS_EDIT_DRIVER_STEP_3,
  DATA_PROCESS_EDIT_DRIVER_STEP_4,
  DATA_PROCESS_EDIT_DRIVER_STEP_5,
  DATA_PROCESS_EDIT_DRIVER_STEP_6,
  DATA_PROCESS_EDIT_DRIVER_STEP_7,
  DATA_PROCESS_EDIT_DRIVER_STEP_8,
  DATA_PROCESS_EDIT_DRIVER_STEPS_OBJECT,
  DATA_PROCESS_MAIN_DRIVER_STEP_1,
  DATA_PROCESS_MAIN_DRIVER_STEP_2,
  DATA_PROCESS_MAIN_DRIVER_STEP_3,
  DATA_PROCESS_MAIN_DRIVER_STEP_4,
  DATA_PROCESS_MAIN_DRIVER_STEP_5,
  DATA_PROCESS_MAIN_DRIVER_STEPS_OBJECT,
  DATA_PROCESS_MAIN_DRIVER_STEPS_WITH_PERMISSIONS,
  DATA_PROCESS_NEW_DRIVER_STEP_1,
  DATA_PROCESS_NEW_DRIVER_STEP_10,
  DATA_PROCESS_NEW_DRIVER_STEP_11,
  DATA_PROCESS_NEW_DRIVER_STEP_2,
  DATA_PROCESS_NEW_DRIVER_STEP_3,
  DATA_PROCESS_NEW_DRIVER_STEP_4,
  DATA_PROCESS_NEW_DRIVER_STEP_5,
  DATA_PROCESS_NEW_DRIVER_STEP_6,
  DATA_PROCESS_NEW_DRIVER_STEP_7,
  DATA_PROCESS_NEW_DRIVER_STEP_8,
  DATA_PROCESS_NEW_DRIVER_STEP_9,
  DATA_PROCESS_RESUME_DRIVER_STEP_1,
  DATA_PROCESS_RESUME_DRIVER_STEP_2,
  DATA_PROCESS_RESUME_DRIVER_STEP_3,
  DATA_PROCESS_RESUME_DRIVER_STEPS_OBJECT,
  DATA_PROCESS_SHOW_DRIVER_STEP_1,
  DATA_PROCESS_SHOW_DRIVER_STEPS_OBJECT,
  DATA_PROCESS_SOURCES_FIELD,
  DATA_PROCESS_SUGGESTED_DRIVER_STEP_1,
  DATA_PROCESS_SUGGESTED_DRIVER_STEP_2,
  DATA_PROCESS_SUGGESTED_DRIVER_STEP_3,
  DATA_PROCESS_SUGGESTED_DRIVER_STEP_4,
  DATA_PROCESS_SUGGESTED_DRIVER_STEP_5,
  DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_1,
  DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_2,
  DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_3,
  DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_1,
  DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEP_2,
  DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEPS_OBJECT,
  DATA_TREATMENT_AGENT_STEP,
  DATA_TREATMENT_STEP,
  DATA_TYPE_OPTIONS,
  DEPARTMENT_DESCRIPTION,
  DEPARTMENT_SOURCE,
  DISAPPROVED_LIA,
  DISAPPROVED_STATUS,
  DISCARDED,
  DOCUMENT_STEP,
  FRAGILITY_ENTITIES_STEP,
  FRAGILITY_FILTER_OPTIONS,
  GENERAL_INFORMATION_STEP,
  INACTIVE,
  LAST_ID_BEFORE_UPDATE_DESCRIPTION,
  LIST_IN_CARD_TYPE,
  LIST_IN_TABLE_TYPE,
  NECESSITY_PROPORTIONALITY_STEP,
  PENDING_LIA,
  PENDING_STATUS,
  PERMITTED_DOCUMENTS_TYPE,
  PERMITTED_STATUS_SHOW_LIA,
  PHYSIC_PROTECTION,
  PUBLIC_SOURCE_DESCRIPTION,
  PUBLIC_SOURCE,
  QUESTION_DRIVER_STATUS_ID,
  REVIEW_LIA_CLIENT,
  REVIEW_LIA_DPO,
  REVIEW_LIA_STATUSES,
  REVIEWING,
  REVISION_STEP,
  SHARE_TYPE_OPTIONS,
  SOURCE_DEPARTMENT_KIND,
  SOURCE_DESCRIPTION_FIELD,
  SOURCE_PUBLIC_KIND,
  SOURCE_THIRD_PARTY_KIND,
  SOURCES_WITH_ENTITY,
  STORAGE_DEFINED_TYPE,
  STORAGE_PERMANENT_TYPE,
  STORAGE_UNDEFINED_TYPE,
  STORY_MODE_DEFINED_TYPE,
  STORY_MODE_PERMANENT_TYPE,
  STORY_MODE_UNDEFINED_TYPE,
  SUGGESTED_APPROVING_STATUS,
  SUGGESTED_OR_MAIN_DRIVER_ID,
  SUGGESTED_PENDING_STATUS,
  SUGGESTED_REFUSE,
  SUGGESTED,
  TABS,
  THIRD_PARTY_SOURCE_DESCRIPTION,
  THIRD_PARTY_SOURCE,
  TITULAR_DATA_SOURCE,
  TITULAR_DESCRIPTION,
  WAITING_REVIEW_STATUS,
  DATA_PROCESS_SHOW_DRIVER_STEP_2,
  DATA_PROCESS_EDIT_DRIVER_STEP_9,
}
