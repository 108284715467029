import components from '../../components'
import templates from '../../templates'

import {
  calculateAspectRatioFit,
  convertObjectToCanvas,
  ratio,
} from '../../helpers'

import palette from 'theme/palette'
import { PADDING, GAP, fontSize } from '../../constants'
import helpers from '../helpers'

const generalInformation = async ({ pdf, date, customStyles, fragilities }) => {
  const { companyFragilitiesTotal, withCompanyFragilitiesSecuresTotal } =
    helpers.questionControlsWithCompanyFragilties(fragilities)

  templates.chapterCover(
    pdf,
    'VISÃO GERAL',
    'Síntese das principais informações e métricas apresentadas na plataforma.',
    customStyles,
  )

  pdf.addPage('a4', 'p')
  components.numberPage(pdf, palette.primary.main)
  components.lineWithDate(pdf, date)

  components.subtitle(pdf, 'Visão geral', GAP)

  components.rectangle(
    pdf,
    PADDING,
    GAP * 1.25,
    ratio(720),
    palette.primary.main,
    ratio,
    'Ameaças',
    companyFragilitiesTotal,
  )

  components.rectangle(
    pdf,
    PADDING * 5,
    GAP * 1.25,
    ratio(720),
    palette.primary.main,
    ratio,
    'Tratados',
    withCompanyFragilitiesSecuresTotal,
  )

  components.text({
    pdf,
    sub: 'Distribuição de ameaças por grau de severidade',
    positionY: GAP * 3.4,
    customFontSize: fontSize.paragraph,
  })

  const distributionThreatsBySeverity = await convertObjectToCanvas(
    'apexchartsdistribution-of-threats-by-severity',
  )

  const distributionThreatsBySeverityProportions = calculateAspectRatioFit(
    distributionThreatsBySeverity.width,
    distributionThreatsBySeverity.height,
    400.5,
    183.8,
  )

  pdf.addImage(
    distributionThreatsBySeverity.toDataURL('image/png'),
    'PNG',
    PADDING * 0.7,
    GAP * 3.5,
    distributionThreatsBySeverityProportions.width,
    distributionThreatsBySeverityProportions.height,
  )

  components.text({
    pdf,
    sub: 'Departamentos por maior quantidade de ameaças',
    positionY: GAP * 7.4,
    customFontSize: fontSize.paragraph,
  })

  const departmentsWithHighestThreats = await convertObjectToCanvas(
    'apexchartsdepartments-with-the-highest-number-of-threats',
  )

  const departmentsWithHighestThreatsProportions = calculateAspectRatioFit(
    departmentsWithHighestThreats.width,
    departmentsWithHighestThreats.height,
    360.5,
    125,
  )

  pdf.addImage(
    departmentsWithHighestThreats.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 7.5,
    departmentsWithHighestThreatsProportions.width,
    departmentsWithHighestThreatsProportions.height,
  )

  pdf.addPage('a4', 'p')
  components.numberPage(pdf, palette.primary.main)
  components.lineWithDate(pdf, date)

  components.text({
    pdf,
    sub: 'Departamentos por maior severidade de ameaças',
    positionY: GAP,
    customFontSize: fontSize.paragraph,
  })

  const departmentsGreaterThreatSeverity = await convertObjectToCanvas(
    'apexchartsdepartments-to-greater-threat-severity',
  )

  const departmentsGreaterThreatSeverityProportions = calculateAspectRatioFit(
    departmentsGreaterThreatSeverity.width,
    departmentsGreaterThreatSeverity.height,
    360.5,
    125,
  )

  pdf.addImage(
    departmentsGreaterThreatSeverity.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 1.1,
    departmentsGreaterThreatSeverityProportions.width,
    departmentsGreaterThreatSeverityProportions.height,
  )

  components.text({
    pdf,
    sub: 'Departamentos por menor severidade de ameaças',
    positionY: GAP * 4.1,
    customFontSize: fontSize.paragraph,
  })

  const departmentsLowerThreatSeverity = await convertObjectToCanvas(
    'apexchartsdepartments-to-lower-threat-severity',
  )

  const departmentsLowerThreatSeverityProportions = calculateAspectRatioFit(
    departmentsLowerThreatSeverity.width,
    departmentsLowerThreatSeverity.height,
    360.5,
    125,
  )

  pdf.addImage(
    departmentsLowerThreatSeverity.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 4.2,
    departmentsLowerThreatSeverityProportions.width,
    departmentsLowerThreatSeverityProportions.height,
  )

  components.text({
    pdf,
    sub: 'Ameaças por local de ocorrência',
    positionY: GAP * 7.2,
    customFontSize: fontSize.paragraph,
  })

  const byOcurrenLocation = await convertObjectToCanvas(
    'apexchartsfragilities-by-ocurrence-location',
  )

  const byOcurrenLocationProportions = calculateAspectRatioFit(
    byOcurrenLocation.width,
    byOcurrenLocation.height,
    360.5,
    125,
  )

  pdf.addImage(
    byOcurrenLocation.toDataURL('image/png'),
    'PNG',
    PADDING,
    GAP * 7.3,
    byOcurrenLocationProportions.width,
    byOcurrenLocationProportions.height,
  )
}

export default generalInformation
