import useAuth from './useAuth'
import constants from 'constants/index'

const usePermissions = () => {
  const auth = useAuth()

  const isAdmin = () => auth.user.admin

  const isSupplier = () =>
    auth?.userCompany?.profile?.id === constants.profile.SUPPLIER_ID

  const isDpo = () =>
    auth?.userCompany?.profile?.id ===
    constants.profile.OUTSOURCED_DPO_PROFILE_ID

  const isResponsible = () =>
    auth?.userCompany?.profile?.id === constants.profile.RESPONSABLE_USER_ID

  const isPmo = () =>
    auth?.userCompany?.profile?.originProfileId === constants.profile.PMO_ID

  const permitted = (tag) => {
    if (isAdmin()) {
      return true
    }

    const permission = auth.permissions.find(
      (permission) => permission.tag === tag,
    )

    if (!permission) {
      return true
    }

    return permission.status
  }

  const some = (tags) => tags.some(permitted)

  return { permitted, some, isAdmin, isSupplier, isDpo, isResponsible, isPmo }
}

export default usePermissions
