import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'

import { DialogLogs, LoadingFeedback, MenuButton, Permitted } from 'components'
import { Head as HeadComponent } from 'views/ThirdPartyManagements/components'

import useFetch from 'hooks/useFetch'

import { routes } from 'Routes'
import { PartnerCompliancePdf } from './components'
import permissions from 'constants/permissions'
import * as service from 'service'
import helpers from 'helpers'

const Head = ({ supplierInvite }) => {
  const [generateReport, setGenerateReport] = useState(false)
  const [openLogDialog, setOpenLogDialog] = useState(false)

  const history = useHistory()

  const { response: responseLogs, isLoading: isLoadingLogs } = useFetch(
    service.dponet.supplierInvite.logs,
    {
      supplierInviteId: supplierInvite?.id,
    },
    [openLogDialog],
    openLogDialog,
  )
  const logs = helpers.logs.changeLogs(responseLogs?.data?.dponetAudits || [])

  const supplierName = supplierInvite?.supplier?.name || ''

  const handleEdit = () => {
    history.push(
      reverse(routes.thirdPartyManagements.partners.edit, {
        supplierInviteId: supplierInvite?.id,
      }),
    )
  }

  return (
    <HeadComponent
      title={supplierName}
      subtitle={supplierInvite?.supplier?.document}
      showActions
    >
      <Box>
        <MenuButton>
          <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
            <Button color="secondary" onClick={handleEdit} fullWidth>
              Editar
            </Button>
          </Permitted>

          <Button
            color="secondary"
            onClick={() => setGenerateReport(true)}
            fullWidth
          >
            Baixar Relatório
          </Button>

          <Button
            color="secondary"
            onClick={() => setOpenLogDialog(true)}
            fullWidth
          >
            Histórico
          </Button>
        </MenuButton>
      </Box>

      {generateReport && (
        <PartnerCompliancePdf
          supplierInvite={supplierInvite}
          generateReport={generateReport}
          setGenerateReport={setGenerateReport}
        />
      )}

      <DialogLogs
        logs={isEmpty(logs) ? undefined : logs}
        open={openLogDialog}
        setOpen={setOpenLogDialog}
        showRegisterSession={false}
      />

      <LoadingFeedback open={isLoadingLogs} />
    </HeadComponent>
  )
}

export default Head
