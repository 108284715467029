import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import qs from 'query-string'
import clsx from 'clsx'
import { Alert } from '@material-ui/lab'
import {
  Button,
  Box,
  CircularProgress,
  Paper,
  Typography,
  Tooltip,
  Tabs,
  Tab,
} from '@material-ui/core'

import { ContentHeader, DriveTour } from 'components'
import {
  DataProcessMenuItems,
  PaperBody,
  ProcessOnAuditAlert,
} from './components'
import LegitimateInterest from '../LegitimateInterest'

import useAuth from 'hooks/useAuth'
import useDataProcessOptions from 'hooks/useDataProcessOptions'

import DataProcessProvider from 'providers/DataProcessProvider'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import useStyles from '../../styles'

const PaperShowDataProcess = ({ match, processId, onlyShow }) => {
  const [dataProcess, setDataProcess] = useState()
  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [logs, setLogs] = useState()
  const [thisIsProcessUndefined, setThisIsProcessUndefined] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [openTour, setOpenTour] = useState(false)
  const [dataProcessTemplate, setDataProcessTemplate] = useState([])
  const [activeTab, setActiveTab] = useState(0)

  const { user } = useAuth()

  const classes = useStyles()
  const location = useLocation()
  const search = qs.parse(location.search)
  const dataProcessId = match?.params?.dataProcessId || processId

  const dataProcessOptions = useDataProcessOptions()

  const suggestedChangeReasons = dataProcess?.suggestedChangeReasons

  const refresh = () => {
    setReload(true)
    setReload(false)
  }

  useEffect(() => {
    setLoading(true)

    if (location?.state?.search) {
      localStorage.setItem('ProcessFilters', location.state.search)
    }

    const loadDataProcess = async () => {
      let response = undefined

      if (!search.showTemplate) {
        response = await service.dponet.dataProcesses.get({
          dataProcessId,
        })
      } else {
        response = await service.dponet.dataProcesses.get({
          dataProcessId,
          ...{ showTemplate: true },
        })
      }

      const dataProcess = response.data.dataProcess
      setDataProcess(dataProcess)

      const storageMode = dataProcess?.storageMode
      if (storageMode === constants.dataProcess.STORY_MODE_UNDEFINED_TYPE) {
        setThisIsProcessUndefined(true)
        let generalInformation =
          dataProcess?.storageMode ===
          constants.dataProcess.STORY_MODE_UNDEFINED_TYPE
        let lifeCycles = dataProcess?.lifeCycles
        let lifeCyclesUndefined = false
        if (
          lifeCycles.find(
            (lifeCycle) =>
              lifeCycle.storageMode ===
              constants.dataProcess.STORY_MODE_UNDEFINED_TYPE,
          )
        ) {
          lifeCyclesUndefined = true
        }

        generalInformation && lifeCyclesUndefined
          ? setAlertMessage(
              'tempo de armazenamento do processo; tempo de armazenamento da salvaguarda e ciclo de vida.',
            )
          : generalInformation
          ? setAlertMessage('tempo de armazenamento do processo')
          : setAlertMessage(
              'tempo de armazenamento da salvaguarda e ciclo de vida',
            )
      }
    }

    const loadDataProcessLogs = async () => {
      if (!search.showTemplate) {
        let response = await service.dponet.dataProcesses.logs({
          dataProcessId,
        })
        setLogs(response?.data?.dponetAudits || [])
      } else {
        setLogs({})
      }
    }

    const loadDataProcessTemplate = async () => {
      const response = await service.dponet.dataProcessTemplates.get({})
      setDataProcessTemplate(response?.data?.dataProcessTemplate || [])
    }

    loadDataProcess()
      .then(() => loadDataProcessLogs())
      .then(() => loadDataProcessTemplate())
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [dataProcessId, reload])

  const handleOpenTour = () => {
    const where = 'Processos'
    const title = 'Tour'

    const params = {
      action: 'tour-visualizar-processos',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const handleChangeTab = (tab) => {
    if (tab === 0) {
      setReload((prevState) => !prevState)
    }
    setActiveTab(tab)
  }

  useEffect(() => {
    if (!dataProcessOptions.data) {
      dataProcessOptions.loadData(user?.company?.id || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProcessOptions.data])

  return (
    <>
      {loading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!onlyShow ? (
            <>
              <Box py={2} mt={1}>
                <ContentHeader title="Processo">
                  {activeTab === 0 && (
                    <Tooltip title="Iniciar o tour guiado">
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<HelpCircleIcon size={20} />}
                        onClick={handleOpenTour}
                        fullWidth
                      >
                        Tutorial
                      </Button>
                    </Tooltip>
                  )}
                  <Box className={classes.contentHeaderBox}>
                    <DataProcessMenuItems
                      dataProcess={dataProcess}
                      origin={search.origin}
                      dataProcessHook={dataProcessOptions}
                      logs={logs}
                      setLoading={setLoading}
                      onEvent={refresh}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    />
                  </Box>
                </ContentHeader>
                {thisIsProcessUndefined && (
                  <Alert
                    className={classes.alertUndefinedProcesses}
                    variant="filled"
                    severity="info"
                  >
                    Atenção! Os dados deste processo estão sem prazo de
                    eliminação definido. Pendência(s): {alertMessage}
                  </Alert>
                )}
                {dataProcess?.processesUnnecessary && (
                  <Alert
                    className={classes.alertUndefinedProcesses}
                    variant="filled"
                    severity="info"
                  >
                    Atenção! Este processo está com o tratamento de necessidade
                    e proporcionalidade pendente.
                  </Alert>
                )}
                {dataProcess?.onGoingAuditProcess && (
                  <ProcessOnAuditAlert
                    refresh={refresh}
                    auditProcess={dataProcess?.onGoingAuditProcess}
                  />
                )}
              </Box>

              {!isEmpty(suggestedChangeReasons) && (
                <Alert
                  className={classes.alertUndefinedProcesses}
                  variant="filled"
                  severity="info"
                >
                  {suggestedChangeReasons.map((reason) => (
                    <Typography variant="h6" key={reason.id}>
                      {reason.description}
                    </Typography>
                  ))}
                </Alert>
              )}

              <Tabs component={Box} mb={3} value={activeTab}>
                <Tab
                  value={0}
                  label="Processo Completo"
                  onClick={() => handleChangeTab(0)}
                />
                {!isEmpty(dataProcess?.liaAnswers) &&
                  constants.dataProcess.PERMITTED_STATUS_SHOW_LIA.includes(
                    dataProcess?.statusId,
                  ) && (
                    <Tab
                      value={1}
                      label="Legítimo Interesse (LIA)"
                      onClick={() => handleChangeTab(1)}
                    />
                  )}
              </Tabs>

              {activeTab === 0 && (
                <Paper className={classes.paperMargin}>
                  <PaperBody
                    isLoading={loading}
                    dataProcess={dataProcess}
                    origin={search?.origin}
                    onlyShow={onlyShow}
                    refresh={refresh}
                    dataProcessTemplate={dataProcessTemplate}
                  />
                </Paper>
              )}

              {activeTab === 1 && (
                <DataProcessProvider>
                  <LegitimateInterest dataProcess={dataProcess} show />
                </DataProcessProvider>
              )}

              <DriveTour
                stepsMatrix={
                  constants.dataProcess.DATA_PROCESS_SHOW_DRIVER_STEPS_OBJECT
                }
                customStyle={classes.customPopover}
                open={openTour}
                setOpen={setOpenTour}
              />
            </>
          ) : (
            <Box>
              <PaperBody
                isLoading={loading}
                dataProcess={dataProcess}
                onlyShow={onlyShow}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}

PaperShowDataProcess.propTypes = {
  processId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onlyShow: PropTypes.bool,
}

PaperShowDataProcess.defaultValues = {
  processId: '',
  onlyShow: false,
}

export default PaperShowDataProcess
