import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Hidden,
  Tab,
  TablePagination,
  Tabs,
  Tooltip,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'

import { Container, ContentHeader, DriveTour, Page } from 'components'
import { TitularsSolicitationsTable } from './components'

import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import helpers from 'helpers'

import * as service from 'service'
import constants from 'constants/index'

const TitularsSolicitationsMain = () => {
  const {
    ALL_STATUSES_TITULAR_SOLICITATION,
    PENDINGS_STATUSES,
    COMPLETED_STATUS_ID,
    SOLICITATION_TICKETS_TABS,
    TITULAR_TICKET_TYPE,
    RESPONDING_STATUSES,
  } = constants.tickets

  const [tab, setTab] = useState(SOLICITATION_TICKETS_TABS[0].value)
  const [statuses, setStatuses] = useState(ALL_STATUSES_TITULAR_SOLICITATION)
  const [openTour, setOpenTour] = useState(false)

  const { mixpanel } = helpers

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value === SOLICITATION_TICKETS_TABS[0].value) {
      return setStatuses(ALL_STATUSES_TITULAR_SOLICITATION)
    }
    if (value === SOLICITATION_TICKETS_TABS[1].value) {
      return setStatuses(PENDINGS_STATUSES)
    }
    if (value === SOLICITATION_TICKETS_TABS[2].value) {
      return setStatuses(RESPONDING_STATUSES)
    }
    setStatuses(COMPLETED_STATUS_ID)
  }

  const { response, isLoading, refresh } = useFetch(
    service.dponet.tickets.getAll,
    {
      perPage,
      page,
      ticketType: TITULAR_TICKET_TYPE,
      statuses_ids: statuses,
    },
    [perPage, page, statuses],
  )

  const tickets = response?.data?.tickets

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Solicitação de titulares', 'Tour', {
      action: 'tour-solicitacao-de-titulares-privacidade-e-voce',
    })
  }

  const filteredSteps =
    constants.tickets.TITULAR_SOLICITATIONS_DRIVER_STEPS_OBJECT.filter(
      (step) => {
        if (isEmpty(tickets)) {
          const mainDriverStep2 =
            '#' + constants.tickets.TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_2
          const mainDriverStep3 =
            '#' + constants.tickets.TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_3
          return (
            step.element !== mainDriverStep2 && step.element !== mainDriverStep3
          )
        }
        return true
      },
    )

  return (
    <Page title="Solicitações de titulares">
      <Container maxWidth={false}>
        <Box>
          <ContentHeader title="Solicitações de titulares">
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={constants.tickets.TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_1}
              >
                Tutorial
              </Button>
            </Tooltip>
          </ContentHeader>
        </Box>
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={() => handleChangeTab}
          >
            {constants.tickets.SOLICITATION_TICKETS_TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(event) => handleChangeTab(event, tab.value)}
              />
            ))}
          </Tabs>
        </Box>
        {!isLoading && (
          <Card>
            <TitularsSolicitationsTable tickets={tickets} refresh={refresh} />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              rowsPerPageOptions={[5, 10, 25, 100]}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Card>
        )}
      </Container>
      <DriveTour
        stepsMatrix={filteredSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default TitularsSolicitationsMain
