import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.menuTitle,
    cursor: 'pointer',
  },
  menu: {
    color: theme.palette.dashboard.text,
  },
  textLighten: {
    color: theme.palette.common.white,
  },
}))

export default useStyles
