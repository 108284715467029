import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { Box, CircularProgress, Typography } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, Permitted, ButtonAction } from 'components'
import ChangeStatusDialog from '../ChangeStatusDialog'

import helpers from 'helpers'

import { routes } from 'Routes'
import constants from 'constants/index'

const TableRowUser = ({ userCompany, refresh, profiles, ...rest }) => {
  const history = useHistory()
  const [changeStatusDialog, setChangeStatusDialog] = useState(false)
  const [changeStatusUserCompany, setChangeStatusUserCompany] = useState()
  const [inactivate, setInactivate] = useState(false)

  const navigateToEdit = (userId) => {
    history.push(
      reverse(routes.user.edit, {
        userId: userId,
      }),
    )
  }

  const handleChangeStatusUserCompany = (userCompany) => {
    setChangeStatusUserCompany(userCompany)
    setChangeStatusDialog(true)
  }

  const handleUserCompanyInactivate = (userCompany) => {
    setChangeStatusUserCompany(userCompany)
    setInactivate(true)
    setChangeStatusDialog(true)
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{userCompany?.id}</TableCell>
        <TableCell>
          <Typography variant="h6">{userCompany?.user?.name}</Typography>
          <Box pt={1}>
            <Typography variant="body2" color="secondary">
              {userCompany?.user?.email}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          {userCompany?.status === constants.userCompanies.ACTIVE
            ? 'Ativo'
            : 'Inativo'}
        </TableCell>
        <TableCell>{userCompany?.profile?.name || '-'}</TableCell>
        <TableCell>{helpers.formatters.date(userCompany?.createdAt)}</TableCell>
        <TableCell align="right">
          <Permitted someTags={['edit_user', 'soft_delete_user_company']}>
            <MenuButton id={constants.user.USER_MAIN_DRIVER_STEP_3}>
              {userCompany?.status === constants.userCompanies.ACTIVE && (
                <Permitted tag="edit_user">
                  <ButtonAction
                    color="secondary"
                    action={() => navigateToEdit(userCompany?.user?.id)}
                    fullWidth
                    size="small"
                    name="Editar"
                  />
                </Permitted>
              )}
              <Permitted tag="soft_delete_user_company">
                <ButtonAction
                  color="secondary"
                  action={() => handleChangeStatusUserCompany(userCompany)}
                  fullWidth
                  size="small"
                  name="Excluir"
                />
              </Permitted>
              <Permitted tag="edit_user">
                <ButtonAction
                  color="secondary"
                  action={() => handleUserCompanyInactivate(userCompany)}
                  fullWidth
                  size="small"
                  name={
                    userCompany?.status === constants.userCompanies.ACTIVE
                      ? 'Inativar'
                      : 'Ativar'
                  }
                />
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
      <ChangeStatusDialog
        inactivateUser={inactivate}
        setInactivateUser={setInactivate}
        open={changeStatusDialog}
        refresh={refresh}
        profiles={profiles}
        setOpen={setChangeStatusDialog}
        user={changeStatusUserCompany?.user}
        userCompanyStatus={changeStatusUserCompany?.status}
        userCompanyId={changeStatusUserCompany?.id}
      />
    </>
  )
}

const UsersTable = ({ userCompanies, isLoading, profiles, refresh }) => {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar>
            <Table
              size="medium"
              emptyMessage="Nenhum usuário encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Identificador</TableCell>
                  <TableCell width="30%">Usuário</TableCell>
                  <TableCell width="10%">Status</TableCell>
                  <TableCell width="20%">Perfil</TableCell>
                  <TableCell width="10%">Criado em</TableCell>
                  <TableCell width="10%" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {userCompanies?.map((userCompany) => (
                  <TableRowUser
                    userCompany={userCompany}
                    key={userCompany.id}
                    profiles={profiles}
                    refresh={refresh}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

TableRowUser.propTypes = {
  userCompany: PropTypes.object,
  refresh: PropTypes.func,
  profiles: PropTypes.array,
}

TableRowUser.defaultProps = {
  refresh: () => {},
}

UsersTable.propTypes = {
  userCompany: PropTypes.object,
  refresh: PropTypes.func,
  isLoading: PropTypes.bool,
  profiles: PropTypes.array,
}

UsersTable.defaultProps = {
  refresh: () => {},
}

export default UsersTable
