import React, { useState } from 'react'
import { Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import {
  Edit as EditIcon,
  Eye as EyeIcon,
  Trash as TrashIcon,
  XCircle as InactiveIcon,
  CheckCircle as CheckIcon,
  XCircle as RefuseIcon,
  RefreshCcw as RepeatIcon,
  Copy as CopyIcon,
  Edit3 as Edit3Icon,
} from 'react-feather'
import { Skeleton } from '@material-ui/lab'

import usePermission from 'hooks/usePermissions'
import useSnackbar from 'hooks/useSnackbar'

import { MenuButton, LabelIconStart, ConfirmationRiskDialog } from 'components'
import {
  InactivateDataProcessDialog,
  ReactivateDataProcessDialog,
  DeleteDataProcessDialog,
  DialogAcceptSuggested,
  DialogRefuseSuggested,
  DialogReopen,
  DialogCopy,
  DialogConfirm,
} from 'views/DataProcesses/components'

import constants from 'constants/index'
import { routes } from 'Routes'
import helpers from 'helpers'

import * as service from 'service'

const DataProcessCardMenuItems = ({
  dataProcess,
  status,
  isLoading,
  origin,
  departments,
  setIsLoading,
  onEvent,
}) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const hasOrigin = dataProcess.originDataProcessId !== null

  const {
    DISAPPROVED_STATUS,
    INACTIVE,
    PENDING_STATUS,
    PENDING_LIA,
    DISAPPROVED_LIA,
  } = constants.dataProcess

  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)
  const [openReactivateDialog, setOpenReactivateDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openCopyDialog, setOpenCopyDialog] = useState(false)
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false)
  const [refuseDialog, setRefuseDialog] = useState({
    open: false,
    message: 'Deseja recusar a sugestão do processamento de dados atual?',
    type: 'refuse',
  })
  const [dialogReopen, setDialogReopen] = useState(false)
  const [confirmRiskOpen, setConfirmRiskOpen] = useState(false)
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
  const [adoptedFragilityId, setAdoptedFragilityId] = useState(null)
  const [defaultFragilityId, setDefaultFragilityId] = useState(null)

  const enableButton =
    helpers.dataProcess.statusesCheck.isPending(dataProcess) ||
    helpers.dataProcess.statusesCheck.disapproved(dataProcess)

  const openRefuse = (props) => {
    setRefuseDialog({ ...refuseDialog, ...props })
  }

  const { permitted, isDpo } = usePermission()

  const handleGoEdit = () =>
    history.push(
      reverse(routes.dataProcess.edit, { dataProcessId: dataProcess.id }),
    )

  const handleGoView = () =>
    history.push(
      reverse(routes.dataProcess.view, { dataProcessId: dataProcess.id }),
    )

  const handleDialogSendToReview = () => {
    if (dataProcess?.acceptProcessFragility) return setConfirmRiskOpen(true)
    return setModalConfirmOpen(true)
  }

  const allowReopen = () => {
    return (
      permitted('reopen_data_process') &&
      helpers.dataProcess.statusesCheck.approved(dataProcess)
    )
  }

  const allowCopy = () => {
    return (
      permitted('list_data_process') &&
      helpers.dataProcess.statusesCheck.approved(dataProcess) &&
      !isDpo()
    )
  }

  const cardActionsSuggested = [
    {
      icon: <CheckIcon size={20} />,
      label: 'Aceitar',
      action: () => setOpenAcceptDialog(true),
      visible: true,
    },
    {
      icon: <RefuseIcon size={20} />,
      label: 'Recusar',
      action: () => openRefuse({ open: true }),
      visible: true,
    },
  ]

  const cardActionsDiscarded = [
    {
      icon: <CheckIcon size={20} />,
      label: 'Aceitar',
      action: () => setOpenAcceptDialog(true),
      visible: true,
    },
  ]

  const items = [
    {
      icon: <EyeIcon size={20} />,
      label: 'VISUALIZAR',
      action: handleGoView,
      visible: permitted('list_data_processes'),
    },
    {
      icon: <Edit3Icon size={20} />,
      label: 'ENVIAR PARA REVISÃO',
      action: handleDialogSendToReview,
      visible: permitted('create_data_process') && enableButton,
    },
    {
      icon: <RepeatIcon size={20} />,
      label: 'REABRIR',
      action: () => setDialogReopen(true),
      visible: allowReopen(),
    },
    {
      icon: <EditIcon size={20} />,
      label: 'EDITAR',
      action: handleGoEdit,
      visible:
        permitted('create_data_process') &&
        [
          DISAPPROVED_STATUS,
          PENDING_STATUS,
          PENDING_LIA,
          DISAPPROVED_LIA,
        ].includes(status),
    },
    {
      icon: <InactiveIcon size={20} />,
      label: 'INATIVAR',
      action: () => setOpenInactivateDialog(true),
      visible:
        permitted('inactive_and_reactive_data_process') &&
        helpers.dataProcess.statusesCheck.allowInactivation(dataProcess),
    },
    {
      icon: <CheckIcon size={20} />,
      label: 'REATIVAR',
      action: () => setOpenReactivateDialog(true),
      visible:
        permitted('inactive_and_reactive_data_process') && status === INACTIVE,
    },
    {
      icon: <TrashIcon size={20} />,
      label: 'EXCLUIR',
      action: () => setOpenDeleteDialog(true),
      visible: !hasOrigin && permitted('destroy_data_process'),
    },
    {
      icon: <TrashIcon size={20} />,
      label: 'EXCLUIR',
      action: () =>
        openRefuse({
          open: true,
          message:
            'Deseja realmente excluir o processo? Todas as alterações feitas no processo serão perdidas',
          type: 'discard',
        }),
      visible: hasOrigin && permitted('destroy_data_process'),
    },
    {
      icon: <CopyIcon size={20} />,
      label: 'COPIAR',
      action: () => setOpenCopyDialog(true),
      visible: allowCopy(),
    },
  ]

  const setMenuItemToCard = (origin) => {
    switch (origin) {
      case 'DataProcessSuggestion':
        return cardActionsSuggested
      case 'DataProcessDiscarded':
        return cardActionsDiscarded
      default:
        return items
    }
  }

  const [stateAction] = useState(setMenuItemToCard(origin))

  const visibleItems = stateAction.filter((item) => item.visible)

  const sendToReview = async () => {
    setIsLoading(true)
    setConfirmRiskOpen(false)
    setModalConfirmOpen(false)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess.id,
        statusId: constants.dataProcess.WAITING_REVIEW_STATUS,
        adoptedFragilityId: adoptedFragilityId || defaultFragilityId,
      })
      snackbar.open({
        message: 'Processo enviado para a revisão!',
        variant: 'success',
      })
      onEvent()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error) ||
          'Ocorreu um erro, tente novamente',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {visibleItems?.length ? (
        <MenuButton buttonClass="class-indexdataprocess-button-options">
          {visibleItems.map((item) => (
            <Button
              key={item.label}
              color="secondary"
              fullWidth
              onClick={item.action}
              disabled={isLoading}
            >
              {isLoading ? (
                <Skeleton variant="rect" width="10vw" height={20} />
              ) : (
                <LabelIconStart icon={item.icon} label={item.label} />
              )}
            </Button>
          ))}
        </MenuButton>
      ) : (
        <Box py={2}></Box>
      )}
      {!isLoading && (
        <>
          {openInactivateDialog && (
            <InactivateDataProcessDialog
              dataProcessId={dataProcess?.id}
              open={openInactivateDialog}
              setOpen={setOpenInactivateDialog}
              action={onEvent}
            />
          )}
          {openReactivateDialog && (
            <ReactivateDataProcessDialog
              dataProcessId={dataProcess?.id}
              open={openReactivateDialog}
              setOpen={setOpenReactivateDialog}
              action={onEvent}
            />
          )}
          {openDeleteDialog && (
            <DeleteDataProcessDialog
              dataProcessId={dataProcess?.id}
              open={openDeleteDialog}
              setOpen={setOpenDeleteDialog}
              action={onEvent}
            />
          )}
          {openAcceptDialog && (
            <DialogAcceptSuggested
              open={openAcceptDialog}
              setOpen={setOpenAcceptDialog}
              dataProcess={dataProcess}
              departments={departments || []}
              setIsLoading={setIsLoading}
            />
          )}
          {refuseDialog && (
            <DialogRefuseSuggested
              refuseDialog={refuseDialog}
              setRefuseDialog={setRefuseDialog}
              dataProcess={dataProcess}
              setIsLoading={setIsLoading}
              onEvent={onEvent}
            />
          )}
          {openCopyDialog && (
            <DialogCopy
              dataProcess={dataProcess}
              open={openCopyDialog}
              setOpen={setOpenCopyDialog}
              action={onEvent}
            />
          )}
          {dialogReopen && (
            <DialogReopen
              open={dialogReopen}
              setOpen={setDialogReopen}
              dataProcess={dataProcess}
              setLoading={setIsLoading}
              refresh={onEvent}
              redirectTo={reverse(routes.dataProcess.edit, {
                dataProcessId: dataProcess?.id,
              })}
            />
          )}
          {modalConfirmOpen && (
            <DialogConfirm
              title="Enviar processo para a revisão"
              description="Deseja enviar esse processo para revisão?"
              confirmText="Enviar para revisão"
              open={modalConfirmOpen}
              setOpen={setModalConfirmOpen}
              handleConfirm={sendToReview}
            />
          )}
          {confirmRiskOpen && (
            <ConfirmationRiskDialog
              setAdoptedFragilityId={setAdoptedFragilityId}
              dataProcessId={dataProcess?.id}
              handleConfirm={sendToReview}
              setOpen={setConfirmRiskOpen}
              open={confirmRiskOpen}
              setDefaultFragilityId={setDefaultFragilityId}
            />
          )}
        </>
      )}
    </>
  )
}

DataProcessCardMenuItems.propTypes = {
  dataProcess: PropTypes.object,
  status: PropTypes.number,
  isLoading: PropTypes.bool,
  origin: PropTypes.string,
  departments: PropTypes.array,
  setIsLoading: PropTypes.func,
  onEvent: PropTypes.func,
}

DataProcessCardMenuItems.defaultProps = {
  setIsLoading: () => {},
  onEvent: () => {},
}

export default DataProcessCardMenuItems
