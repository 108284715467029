import { makeStyles, colors } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  active: {
    borderRadius: '2px',
    backgroundColor: colors.green[500],
    color: colors.common.white,
    fontWeight: 700,
    height: '21px',
  },
  inactive: {
    borderRadius: '2px',
    backgroundColor: colors.red[500],
    color: colors.common.white,
    fontWeight: 700,
    height: '21px',
  },
  customPopover: {
    maxWidth: 500,
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
  },
}))

export default useStyles
