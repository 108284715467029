import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { Box, Menu, IconButton, makeStyles } from '@material-ui/core'

import { MoreVertical } from 'react-feather'

import styles from './styles'

const useStyles = makeStyles(styles)

const MenuButton = ({
  id,
  children,
  anchorElement,
  anchorOptions,
  anchorChildren,
  iconButtonSize = 'medium',
  iconButtonFontSize = 24,
  ...rest
}) => {
  const actionRef = useRef(null)
  const [isMenuOpen, setMenuOpen] = useState(false)

  const classes = useStyles()
  const AnchorElement = anchorElement

  const handleOpen = () => setMenuOpen(true)

  return (
    <>
      <Menu
        anchorEl={actionRef.current}
        onClose={() => setMenuOpen(false)}
        open={isMenuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id={id}
        {...rest}
      >
        <Box className={classes.itemsContainer}>{children}</Box>
      </Menu>
      {!anchorElement ? (
        <IconButton
          component="div"
          id={id}
          ref={actionRef}
          onClick={handleOpen}
          size={iconButtonSize}
        >
          <MoreVertical size={iconButtonFontSize} />
        </IconButton>
      ) : (
        <AnchorElement
          id={id}
          ref={actionRef}
          onClick={handleOpen}
          {...anchorOptions}
        >
          {anchorChildren}
        </AnchorElement>
      )}
    </>
  )
}

MenuButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  anchorElement: PropTypes.object,
  anchorOptions: PropTypes.object,
  iconButtonSize: PropTypes.string,
  iconButtonFontSize: PropTypes.number,
}

export default MenuButton
