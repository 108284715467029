import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Typography, Breadcrumbs, Link } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import clsx from 'clsx'

import useContentHeader from 'hooks/useContentHeader'

import helpers from 'helpers'

import { routes } from 'Routes'
import useStyles from './styles'

const HistoryBreadcumbs = ({ lightColor }) => {
  const [contentHeaderArray, setContentHeaderArray] = useState([])

  const { setHistoryContentHeader, onChangePage } = useContentHeader()
  const routeMatch = useRouteMatch()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    const currentPathObjectFormatted =
      helpers.contentHeader.formatters.routesWithLabel(
        routes,
        routeMatch.path,
        routeMatch.url,
      )

    const pathList = `/${routeMatch.path.split('/')[1]}/`

    const currentListPath = helpers.contentHeader.formatters.routesWithLabel(
      routes,
      pathList,
      pathList,
    )
    onChangePage(currentPathObjectFormatted, currentListPath)
    const newArray =
      JSON.parse(localStorage.getItem('lastArrayContentHeader')) || []

    setHistoryContentHeader(newArray)
    setContentHeaderArray(newArray)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {contentHeaderArray.length > 1 && (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          classes={{
            li: clsx({ [classes.textLighten]: lightColor }),
            ol: clsx({ [classes.textLighten]: lightColor }),
          }}
          aria-label="breadcrumb"
        >
          <Typography
            className={clsx(classes.menu, {
              [classes.textLighten]: lightColor,
            })}
            variant="body1"
            color="textPrimary"
          >
            {helpers.contentHeader.getCurrentScopeByUri(routeMatch.path)}
          </Typography>
          {contentHeaderArray.map((item) => (
            <Link key={item.url} onClick={() => history.push(item.url)}>
              <Typography
                className={clsx(classes.title, {
                  [classes.textLighten]: lightColor,
                })}
                variant="body1"
                color="textPrimary"
              >
                {item.label}
              </Typography>
            </Link>
          ))}
        </Breadcrumbs>
      )}
    </>
  )
}

export default HistoryBreadcumbs
