import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import helpers from 'helpers'

import useStyles from './styles'
import ScannerStatus from '../../../ScannerStatus'

const CompilationDetailsCard = ({ build }) => {
  const classes = useStyles()

  return (
    <Box className={classes.detailsContainer}>
      <Box>
        <Typography color="primary" gutterBottom>
          Status
        </Typography>
        <ScannerStatus status={build?.status} type="build" />
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Data
        </Typography>
        <Typography color="primary" variant="h6">
          {helpers.formatters.ptBrFormatDateTime(build?.createdAt) ||
            'Não encontrada'}
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Última compilação realizada
        </Typography>
        <Typography color="primary" variant="h6">
          {build?.type || 'Manual'}
        </Typography>
      </Box>
    </Box>
  )
}

CompilationDetailsCard.propTypes = {
  build: PropTypes.object,
}

export default CompilationDetailsCard
