import React, { useState } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { Container, ContentHeader, DriveTour, Page } from 'components'
import { AdvisorCard, Footer } from './components'
import { Filters, SuggestionTable } from './components/suggestion'

import useFilter from 'hooks/useFilter'

import constants from 'constants/index'
import inviteStreamlineIcon from 'images/invite_streamline_icon.svg'
import theme from 'theme'

const ThirdPartyManagementSuggestion = () => {
  const [openTour, setOpenTour] = useState(false)

  const filter = useFilter()

  const handleOpenTour = () => setOpenTour(true)

  return (
    <Page title="Gestão de Terceiros - Sugestões">
      <Container maxWidth={false}>
        <ContentHeader title="Sugestões">
          <Button
            id={constants.tours.thirdPartyManagements.STEPS.SUGGESTIONS[1]}
            variant="contained"
            color="primary"
            startIcon={<HelpCircleIcon size={20} />}
            onClick={handleOpenTour}
          >
            Tutorial
          </Button>

          <DriveTour
            stepsMatrix={
              constants.tours.thirdPartyManagements.STEPS_OBJECT.SUGGESTIONS
            }
            open={openTour}
            setOpen={setOpenTour}
          />

          <Filters filter={filter} />
        </ContentHeader>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AdvisorCard
              imageSrc={inviteStreamlineIcon}
              text={constants.thirdPartyManagements.BANNERS.SUGGESTION}
            />
          </Grid>

          <Grid item xs={12}>
            <SuggestionTable filter={filter} />
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementSuggestion
