import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepLabel,
  Typography,
  Grid,
  Box,
} from '@material-ui/core'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons'

import useStyles from './styles'

import useConsent from 'hooks/useConsent'

const Stepper = ({ steps, children, isDisabled = false }) => {
  const classes = useStyles()

  const { setActiveStep, lastActiveStep, setLastActiveStep } = useConsent()

  const handleClick = (index) => {
    if (isDisabled) return

    if (lastActiveStep >= index) {
      setActiveStep(index)
      setLastActiveStep(index)
    }
  }

  const Icon = (props) => {
    let { icon } = props
    icon -= 1
    const active = icon === lastActiveStep
    const completed = icon < lastActiveStep
    return (
      <Box
        className={clsx(classes.stepIcon, {
          [classes.stepIconActive]: active,
          [classes.stepIconCompleted]: completed,
          [classes.stepIconDisabled]: isDisabled,
        })}
      >
        {icon === lastActiveStep ? (
          <RemoveIcon />
        ) : completed ? (
          <CheckIcon />
        ) : (
          <ClearIcon />
        )}
      </Box>
    )
  }

  return (
    <Grid>
      <MuiStepper
        className={clsx(classes.stepper, {
          [classes.stepperDisabled]: isDisabled,
        })}
        activeStep={lastActiveStep}
        nonLinear
        orientation="vertical"
        connector={<StepConnector className={classes.stepConnector} />}
      >
        {steps?.map((step, index) => {
          let labelProps = {}
          if (lastActiveStep < index) {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Responder
              </Typography>
            )
          } else if (lastActiveStep === index) {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Respondendo
              </Typography>
            )
          } else {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Concluído
              </Typography>
            )
          }

          return (
            <Step key={index} id={step.id} className={classes.step}>
              <StepLabel
                StepIconComponent={Icon}
                onClick={() => handleClick(index)}
                className={clsx(classes.stepLabelText, {
                  [classes.stepLabelModify]: index <= lastActiveStep,
                  [classes.stepLabelDisabled]: isDisabled,
                })}
                optional={labelProps}
              >
                {step.label}
              </StepLabel>
            </Step>
          )
        })}
      </MuiStepper>
      <Grid className={classes.actionsContainer}>{children}</Grid>
    </Grid>
  )
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
}

export default Stepper
