import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Container, Box, Button, Tooltip } from '@material-ui/core'
import { isEmpty } from 'lodash'

import {
  Page,
  ContentHeader,
  Permitted,
  DriveTour,
  LoadingFeedback,
} from 'components'
import { DialogHandleUsers, QuestionnairesTabHeader } from './components'

import useAuth from 'hooks/useAuth'
import useFetch from 'hooks/useFetch'
import usePermissions from 'hooks/usePermissions'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'

const QuestionnairesReply = ({ match }) => {
  const [dialogHandleUsers, setDialogHandleUsers] = useState(false)
  const [openTour, setOpenTour] = useState(false)

  const { company, setCompany } = useAuth()
  const permissions = usePermissions()
  const classes = useStyles()

  const { questionnaireId } = match.params

  const { response, isLoading, refresh } = useFetch(
    service.dponet.questionnaires.get,
    {
      questionnaireId,
    },
    [],
    !!questionnaireId,
  )

  const questionnaire = response?.data?.questionnaire ?? {}

  const reload = async () => {
    const responseIdentify = await service.dponet.usersCompanies.identify()
    setCompany(responseIdentify.data?.company ?? company)
    refresh()
  }

  const filtersMatrixSteps = (elementId, permission) => {
    const requiredPermission =
      constants.questionnaire
        .QUESTIONNAIRE_REPLY_DRIVER_STEPS_OBJECT_WITH_PERMISSIONS[elementId]

    if (
      isEmpty(questionnaire?.questions) &&
      elementId ===
        '#' + constants.questionnaire.QUESTIONNAIRE_REPLY_DRIVER_STEP_3
    )
      return false

    return requiredPermission ? permission(requiredPermission) : true
  }

  const filteredSteps =
    constants.questionnaire.QUESTIONNAIRE_REPLY_DRIVER_STEPS_OBJECT.filter(
      (step) => {
        return filtersMatrixSteps(step.element, permissions.permitted)
      },
    )

  const handleOpenTour = () => setOpenTour(true)

  return (
    <>
      <Page title="Responder questionário">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false} className={classes.container}>
          <ContentHeader title={questionnaire?.name}>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
              >
                Tutorial
              </Button>
            </Tooltip>
            <Permitted tag="link_users">
              <Button
                id={constants.questionnaire.QUESTIONNAIRE_REPLY_DRIVER_STEP_1}
                variant="contained"
                color="primary"
                onClick={() => setDialogHandleUsers(true)}
              >
                Gerenciar usuários
              </Button>
            </Permitted>
          </ContentHeader>
          <Box my={2}>
            <QuestionnairesTabHeader
              questionnaire={questionnaire}
              isLoading={isLoading}
              refresh={reload}
              companyKind={company?.kind}
            />
          </Box>
        </Container>
        {response && response.data && (
          <DialogHandleUsers
            questionnaireId={questionnaireId}
            linkedUsers={questionnaire?.users}
            open={dialogHandleUsers}
            onEvent={refresh}
            setOpen={setDialogHandleUsers}
          />
        )}
      </Page>

      <DriveTour
        stepsMatrix={filteredSteps}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </>
  )
}

export default QuestionnairesReply
