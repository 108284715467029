import React, { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { Container, ContentHeader, DriveTour, Page } from 'components'
import { ConsentAnswerForm, ConsentSelectionForm } from './components'

import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'

const ConsentReceiptNew = () => {
  const classes = useStyles()

  const [consentForm, setConsentForm] = useState({})
  const [openTour, setOpenTour] = useState(false)

  const handleOpenTour = () => {
    const where = 'Novo Recibo'
    const title = 'Tour'

    const params = {
      action: 'tour-registrar-recibos-consentimento',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Novo Recibo">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Novo Recibo">
          <Tooltip title="Iniciar o tour guiado">
            <Button
              id={constants.consent.NEW_RECEIPT_DRIVER_STEP_0}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
            >
              Tutorial
            </Button>
          </Tooltip>
        </ContentHeader>
        <Paper className={classes.fullPaperWidth}>
          <Box
            width="100%"
            display="flex"
            p={2}
            alignItems="center"
            fontWeight={500}
          >
            <Typography variant="subtitle1">
              <Box fontWeight="bold">Novo Recibo de Consentimento</Box>
            </Typography>
          </Box>
          <Divider />
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                Preencha os campos de informações gerais do formulário de
                consentimento
              </Typography>
            </Grid>
            <Grid item xs={12} margin>
              <ConsentSelectionForm setConsentForm={setConsentForm} />
            </Grid>
          </Grid>
        </Paper>
        {!!consentForm?.components && (
          <Paper className={classes.fullPaperWidth}>
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12}>
                <ConsentAnswerForm consentForm={consentForm} />
              </Grid>
            </Grid>
          </Paper>
        )}
      </Container>
      <DriveTour
        stepsMatrix={constants.consent.NEW_RECEIPT_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default ConsentReceiptNew
