import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  customPopover: {
    maxWidth: 500,
  },
  contentHeaderBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default styles
