import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { Box, Button, Typography, Tooltip } from '@material-ui/core'

import {
  LoadingFeedback,
  DriveTour,
  ContentHeader,
  ButtonAction,
} from 'components'

import useStyles from './styles'

import constants from 'constants/index'
import helpers from 'helpers'

const Header = ({
  type,
  buttonAction,
  isLoading,
  disabled,
  shouldSubmit,
  reportTour,
  ...rest
}) => {
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()
  const descriptions = constants.reports.DESCRIPTION[type]

  const filteredType =
    constants.reports.REPORTS_DRIVER_TYPE_OBJECT[type]?.object

  const handleOpenTour = () => {
    const where = descriptions?.title
    const title = 'Tour'

    const params = {
      action: `tour-${reportTour}-relatorios`,
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <>
      <LoadingFeedback open={!!isLoading} />
      <ContentHeader title={descriptions?.title || rest.title}>
        <Tooltip title="Iniciar o tour guiado">
          {!!type && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
              id={constants.reports.REPORTS_DRIVER_TYPE_OBJECT[type]?.tour}
            >
              Tutorial
            </Button>
          )}
        </Tooltip>
        <ButtonAction
          {...(shouldSubmit && { form: 'report-form' })}
          type="submit"
          variant="contained"
          color="primary"
          action={buttonAction}
          className={classes.button}
          disabled={disabled}
          id={constants.reports.REPORTS_DRIVER_TYPE_OBJECT[type]?.button}
          name="Gerar Relatório"
        />
      </ContentHeader>
      <Box className={classes.boxContainer}>
        <Typography variant="subtitle1" className={classes.text}>
          {descriptions?.content || rest.description}
        </Typography>

        {!isEmpty(descriptions?.options) && (
          <ul className={classes.listInfo}>
            {descriptions?.options.map((options) => (
              <li key={options}>
                <Typography variant="subtitle1" className={classes.text}>
                  {options}
                </Typography>
              </li>
            ))}
          </ul>
        )}
      </Box>
      <DriveTour
        stepsMatrix={filteredType}
        open={openTour}
        setOpen={setOpenTour}
      />
    </>
  )
}

Header.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  shouldSubmit: PropTypes.bool,
}

Header.defaultProps = {
  buttonAction: () => {},
  shouldSubmit: true,
  disabled: false,
}

export default Header
