import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'

import { Container, Page, RoundedBorder } from 'components'
import { RipdForm, Header, ReportVersionTable } from './components'

import constants from 'constants/index'

import useStyles from './styles'

const ImpactReport = () => {
  const classes = useStyles()
  const [triggerValidation, setTriggerValidation] = useState(() => {})
  const [currentReportVersion, setCurrentReportVersion] = useState()

  return (
    <Page
      className={classes.root}
      title="Relatório de Impacto à Proteção de Dados Pessoais (RIPD)"
    >
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Header
                type="ripd"
                reportTour="ripd"
                {...(!!currentReportVersion?.id && {
                  buttonAction: async () => {
                    const valid = await triggerValidation()
                    if (!valid) return
                  },
                  shouldSubmit: true,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <RipdForm ripd setTriggerValidation={setTriggerValidation} />
            </Grid>
            <Grid item xs={12}>
              <ReportVersionTable
                currentReportVersion={currentReportVersion}
                setCurrentReportVersion={setCurrentReportVersion}
                kind={constants.reportVersions.KIND.RIPD}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default ImpactReport
