// Remover componente quando criar a tela home
import React from 'react'

import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core'
import { Check as CheckIcon, X as CloseIcon } from 'react-feather'

import { RIPD_INCITE_EXPORTED } from 'service/constants'
import useAuth from 'hooks/useAuth'

function RipdIncite({ open = false, setOpen, exportRipd }) {
  const { user, userCompany } = useAuth()

  const concludeIncite = () => {
    localStorage.setItem(RIPD_INCITE_EXPORTED + userCompany?.id, true)
  }

  const action = async () => {
    await exportRipd({ receiverEmail: user?.email })
    concludeIncite()
  }

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return

    concludeIncite()
    setOpen(false)
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <Box display="flex" mt={2} px={2} width="100%" justifyContent="end">
        <IconButton onClick={handleClose}>
          <CloseIcon size={25} />
        </IconButton>
      </Box>
      <Box mx={4} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h3" align="center">
          Seu relatório de impacto (RIPD) já está disponível. Baixe o arquivo e
          guarde-o bem. Se quiser, pode imprimi-lo
        </Typography>
      </Box>
      <Box m={2} display="flex" justifyContent="center">
        <Button
          disableElevation
          startIcon={<CheckIcon />}
          variant="contained"
          color="primary"
          onClick={action}
        >
          Clique aqui para enviar para o seu e-mail
        </Button>
      </Box>
      <Box
        mx={4}
        my={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5" align="center">
          Caso queira se aprofundar mais, acesse o menu "Treinamentos".
        </Typography>
      </Box>
    </Dialog>
  )
}

export default RipdIncite
///////////////////////////////////
