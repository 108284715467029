import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Hidden,
  Tab,
  TablePagination,
  Tabs,
  Tooltip,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  DriveTour,
  FilterButton,
  Filters,
  Page,
  Permitted,
} from 'components'
import { ConsentTable, DialogNewForm } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'
import permissions from 'constants/permissions'

import useStyles from './styles'

const ConsentMain = () => {
  const classes = useStyles()
  const filter = useFilter()

  const [openDialogNewForm, setOpenDialogNewForm] = useState(false)

  const {
    CONSENT_TABS,
    ALL_STATUSES_CONSENT,
    INACTIVE_CONSENT_FORM_STATUS_ID,
    ACTIVE_CONSENT_FORM_STATUS_ID,
    FORM_STATUSES,
  } = constants.consent

  const [tab, setTab] = useState(CONSENT_TABS[0].value)
  const [statuses, setStatuses] = useState(ALL_STATUSES_CONSENT)
  const [templates, setTemplates] = useState([])
  const [openTour, setOpenTour] = useState(false)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value === CONSENT_TABS[0].value) {
      return setStatuses(ALL_STATUSES_CONSENT)
    }
    if (value === CONSENT_TABS[1].value) {
      return setStatuses(ACTIVE_CONSENT_FORM_STATUS_ID)
    }
    if (value === CONSENT_TABS[2].value) {
      return setStatuses(INACTIVE_CONSENT_FORM_STATUS_ID)
    }
  }

  const { response, isLoading, refresh } = useFetch(
    service.dponet.consent.list,
    {
      perPage,
      page,
      statuses: statuses,
      ...filter.filters,
    },
    [perPage, page, statuses, filter.filters],
  )

  const consents = response?.data?.consentForms

  const handleOpenTour = () => {
    const where = 'Formulários de consentimento'
    const title = 'Tour'

    const params = {
      action: 'tour-formularios-consentimento',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Formulários de Consentimento">
      <Container maxWidth={false}>
        <Box>
          <ContentHeader title="Formulários de Consentimento">
            <Tooltip title="Iniciar o tour guiado">
              <Button
                id={constants.consent.NEW_FORM_DRIVER_STEP_0}
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
              >
                Tutorial
              </Button>
            </Tooltip>

            <Permitted tag={permissions.CONSENTS.CREATE}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenDialogNewForm(true)}
              >
                NOVO FORMULÁRIO
              </Button>
            </Permitted>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </ContentHeader>
        </Box>
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={() => handleChangeTab}
          >
            {CONSENT_TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(event) => handleChangeTab(event, tab.value)}
              />
            ))}
          </Tabs>
        </Box>
        {!isLoading && (
          <Card>
            <ConsentTable consents={consents} refresh={refresh} />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              rowsPerPageOptions={[5, 10, 25, 100]}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Card>
        )}
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <select textfieldinput="true" label="Status" name="statuses">
            <option value=""></option>
            {FORM_STATUSES.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
          <input
            textfieldinput="true"
            label="Título do formulário"
            name="title"
          />
          <input
            textfieldinput="true"
            label="Identificador do autor"
            name="authorId"
          />
        </Filters>
      </Container>
      <DialogNewForm
        open={openDialogNewForm}
        setOpen={setOpenDialogNewForm}
        templates={templates}
        setTemplates={setTemplates}
      />
      <DriveTour
        stepsMatrix={constants.consent.NEW_FORM_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default ConsentMain
