import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TablePagination,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'

import { ButtonAction, DriveTour } from 'components'
import {
  Page,
  ContentHeader,
  Permitted,
  Filters,
  FilterButton,
} from 'components'
import ProfilesTable from './components/ProfilesTable'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import usePermissions from 'hooks/usePermissions'

import { routes } from 'Routes'
import useStyles from './styles'
import theme from 'theme'
import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const ProfilesMain = () => {
  const [openTour, setOpenTour] = useState(false)

  const history = useHistory()
  const filter = useFilter()
  const classes = useStyles()
  const permissions = usePermissions()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const hasCreatePermission = permissions.permitted(
    constants.permissions.PROFILES.CREATE,
  )

  const hasEditPermission = permissions.permitted(
    constants.permissions.PROFILES.EDIT,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.profiles.get,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  const profiles = response?.data?.profiles

  const handleOpenTour = () => {
    const where = 'Perfis'
    const title = 'Tour'

    const params = {
      action: 'tour-perfis-empresas',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const filtersMatrixSteps = (elementId, permission) => {
    const requiredPermission =
      constants.profile.PROFILES_DRIVER_STEPS_WITH_PERMISSIONS[elementId]

    if (
      isEmpty(profiles) &&
      elementId === '#' + constants.user.USER_MAIN_DRIVER_STEP_3
    )
      return false

    return requiredPermission ? permission(requiredPermission) : true
  }

  const filteredSteps = constants.profile.PROFILES_DRIVER_STEPS_OBJECT.filter(
    (step) => {
      return filtersMatrixSteps(step.element, permissions.permitted)
    },
  )

  const disableTour =
    !hasCreatePermission && (!hasEditPermission || isEmpty(profiles))

  return (
    <Page title="Todos os Perfis">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Perfis">
          <Tooltip
            title={
              disableTour
                ? "Para iniciar este tour guiado, é necessário que você possua permissões para 'criar' ou 'editar' como algum perfil definido."
                : 'Iniciar o tour guiado'
            }
          >
            <Button
              id={constants.profile.PROFILE_MAIN_DRIVER_STEP_0}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              disabled={disableTour}
              fullWidth
            >
              Tutorial
            </Button>
          </Tooltip>
          <Permitted tag={constants.permissions.PROFILES.CREATE}>
            <ButtonAction
              id={constants.profile.PROFILE_MAIN_DRIVER_STEP_1}
              variant="contained"
              color="primary"
              name="NOVO PERFIL"
              action={() => history.push(routes.profiles.new)}
              fullWidth
            />
          </Permitted>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} fullWidth />
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <ProfilesTable
                profiles={profiles}
                isLoading={isLoading}
                refresh={refresh}
              />
            </Grid>
          </Box>
          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter} alphabeticalOrder>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Nome" name="name" />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={constants.profile.ACTIVE}>Ativo</option>
            <option value={constants.profile.INACTIVE}>Inativo</option>
          </select>
        </Filters>
      </Container>

      <DriveTour
        stepsMatrix={filteredSteps}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}
export default ProfilesMain
