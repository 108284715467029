import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Button, Tooltip } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  DriveTour,
  LoadingFeedback,
  Page,
} from 'components'
import { ConsentForm } from './components'

import useConsent from 'hooks/useConsent'

import helpers from 'helpers'
import * as service from 'service'

import useStyles from './styles'

const ConsentNew = () => {
  const classes = useStyles()
  const location = useLocation()
  const { setDataConsentForm } = useConsent()
  const [screenDescription, setScreenDescription] = useState('')
  const [tourSteps, setTourSteps] = useState([])
  const templateId = location.state?.templateId
  const [loading, setLoading] = useState(!!templateId)
  const [openTour, setOpenTour] = useState(false)

  useEffect(() => {
    const loadTemplate = async () => {
      const response = await service.dponet.consent.getTemplate({
        consentFormId: templateId,
      })
      setDataConsentForm(
        helpers.consent.mountTemplateData(response?.data?.consentForm),
      )
      setLoading(false)
    }

    if (loading) {
      loadTemplate()
    }
  }, [loading, setDataConsentForm, templateId])

  const handleOpenTour = () => {
    const where = 'Novo Formulário'
    const title = 'Tour'

    const params = {
      action: 'tour-novo-formularios-consentimento',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Novo Formulário">
      <Container maxWidth={false}>
        <Box>
          <LoadingFeedback open={loading} />
          <ContentHeader title={screenDescription}>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
              >
                Tutorial
              </Button>
            </Tooltip>
          </ContentHeader>
          {!loading && (
            <ConsentForm
              setScreenDescription={setScreenDescription}
              setTourSteps={setTourSteps}
            />
          )}
        </Box>
      </Container>
      <DriveTour
        stepsMatrix={tourSteps}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default ConsentNew
