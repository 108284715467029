import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Box, Button, Container, Tooltip } from '@material-ui/core'

import { ContentHeader, DriveTour, LoadingFeedback, Page } from 'components'
import { DepartmentsForm } from './components'

import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

import useStyles from './styles'

const DepartmentsNew = () => {
  const [loading, setLoading] = useState(false)
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()

  const { response, isLoading } = useFetch(
    service.dponet.departments.availableUsers,
    {},
  )

  const users = response?.data?.users

  const handleOpenTour = () => {
    const where = 'Novo Departamento'
    const title = 'Tour'

    const params = {
      action: 'tour-novo-departamento-empresas',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Novo departamento">
      <LoadingFeedback open={loading || isLoading} />
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader
          menu="Gerenciamento"
          title="Departamento"
          subtitle="Novo Departamento"
        >
          <Tooltip title="Iniciar o tour guiado">
            <Button
              id={constants.departments.NEW_DEPARTMENT_DRIVER_STEP_0}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
            >
              Tutorial
            </Button>
          </Tooltip>
        </ContentHeader>
        <DepartmentsForm
          setLoading={setLoading}
          formType="new"
          availableUsers={users}
        />
      </Container>
      <DriveTour
        stepsMatrix={constants.departments.NEW_DEPARTMENTS_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DepartmentsNew
