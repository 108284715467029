import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { Loader as LoaderIcon } from 'react-feather'

import { AlertDialog } from 'components'
import { CompilationDetailsCard } from './components'
import BannerHeadCard from '../BannerHeadCard'

import { useFormContext } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'
import styles from './styles'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import theme from 'theme'
import { Alert } from '@material-ui/lab'

const BannerCompilationCard = ({
  banner,
  isCompiling,
  setIsCompiling,
  refresh,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const useStyles = styles(isCompiling)
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { watch, formState } = useFormContext()

  const formUrls = watch('urls') || []
  const bannerUrls = banner?.urls || []
  const isDirty = formState.dirty

  const disableScanner =
    formUrls.length !== bannerUrls.length ||
    formUrls.some((url, index) => url.url !== bannerUrls[index].url)

  const scannerCompleted =
    banner?.scanner?.status ===
    constants.cookies.build.COMPLETED_BUILD_STATUS_ID

  const disableCompile =
    !scannerCompleted || isCompiling || disableScanner || isDirty

  const handleCompile = async () => {
    setIsCompiling(true)
    try {
      await service.cookies.banner.compile({ bannerId: banner.id })
      snackbar.open({
        message:
          'Compilação iniciada com sucesso! Isso pode levar alguns minutos.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setIsCompiling(false)
      refresh()
    }
  }

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  useEffect(() => {
    if (
      banner?.build?.status ===
      constants.cookies.build.INITIATED_BUILD_STATUS_ID
    )
      handleOpenDialog()
  }, [banner?.build?.status])

  return (
    <Paper
      variant="outlined"
      id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_4}
    >
      <BannerHeadCard name="Geração do código do Banner" stage="04" />
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
      >
        <Box
          bgcolor={theme.palette.custom.light}
          borderRadius={1}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(3)}
          p={2}
          textAlign="justify"
        >
          <Typography color="primary">
            Clique no botão "Gerar" para iniciar a compilação do seu banner.
            Nosso sistema irá gerar o código com suas configurações de
            personalização e cookies. Ao final desta etapa, você terá acesso ao
            código de instalação do banner em seu site.
          </Typography>
        </Box>
        <CompilationDetailsCard build={banner?.build} />
        {banner?.build?.status ===
          constants.cookies.build.INITIATED_BUILD_STATUS_ID && (
          <Alert variant="outlined" severity="warning">
            O código do seu banner está sendo gerado, este processo pode levar
            até 5 minutos. Por favor, aguarde alguns instantes e atualize a
            página se necessário. Obrigado pela compreensão!
          </Alert>
        )}
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} md={3} lg={2}>
            <Tooltip
              title={
                (!scannerCompleted &&
                  'A compilação só pode ser feita depois que o scanner for completo.') ||
                (disableScanner &&
                  'Salve as alterações de "Configuração do Banner" antes de prosseguir com a geração do código.') ||
                (isDirty &&
                  'Salve as alterações de "Personalização do Banner" antes de prosseguir com a geração do código.') ||
                ''
              }
            >
              <span>
                <Button
                  startIcon={
                    <LoaderIcon size={18} className={classes.iconAnimation} />
                  }
                  variant="contained"
                  color="primary"
                  disabled={disableCompile}
                  fullWidth
                  onClick={handleCompile}
                >
                  {isCompiling ? 'Gerando...' : 'Gerar'}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <AlertDialog
        open={openDialog}
        onClose={handleCloseDialog}
        variant="outlined"
        severity="warning"
        content="O seu banner está sendo compilado, este processo pode levar até 5
                minutos. Por favor, aguarde alguns instantes e atualize a página se
                necessário. Obrigado pela compreensão!"
      />
    </Paper>
  )
}

BannerCompilationCard.propTypes = {
  banner: PropTypes.object.isRequired,
  isCompiling: PropTypes.bool.isRequired,
  setIsCompiling: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

export default BannerCompilationCard
