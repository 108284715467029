import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import * as service from 'service'

const PartnerCompliancePdf = ({
  supplierInvite,
  generateReport,
  setGenerateReport = () => {},
}) => {
  const snackbar = useSnackbar()
  const { customTheme, customLogo } = useCustomTheme()

  const supplier = supplierInvite?.supplier || {}
  const questionnaires = supplierInvite?.questionnaires || []

  const { response, isLoading } = useFetch(
    service.dponet.questionControls.list,
    {
      withActionPlans: false,
      supplierId: supplier?.id,
      perPage: 9999,
    },
    [supplier],
    !!supplier?.id,
  )

  const questionControls = response?.data?.questionControls

  const exportPdf = (questionnaires, questionControls, supplier) => {
    helpers.reports.supplierCompliance.exportPdf(
      customTheme?.palette?.layout,
      customLogo,
      questionnaires,
      questionControls,
      supplier,
      setGenerateReport,
      snackbar,
    )
  }

  useEffect(() => {
    if (generateReport) {
      if (questionControls && questionnaires && supplier) {
        exportPdf(questionnaires, questionControls, supplier)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionControls])

  return (
    <>
      <LoadingFeedback loading={isLoading} />
    </>
  )
}

export default PartnerCompliancePdf
