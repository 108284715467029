import React, { useState } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import {
  Container,
  ContentHeader,
  DriveTour,
  Page,
  Permitted,
} from 'components'
import { AdvisorCard, FatButton, Footer } from './components'
import { Filters, PreAvaliationTable } from './components/preAvaliation'
import { ImPartnerTable } from './components/partner/list'

import useFilter from 'hooks/useFilter'
import usePermissions from 'hooks/usePermissions'

import constants from 'constants/index'
import inviteStreamlineIcon from 'images/invite_streamline_icon.svg'
import theme from 'theme'
import permissions from 'constants/permissions'
import { routes } from 'Routes'
import useStyles from './components/preAvaliation/styles'

const ThirdPartyManagementPreAvaliation = () => {
  const { isSupplier } = usePermissions()

  const [openTour, setOpenTour] = useState(false)
  const [selectedButton, setSelectedButton] = useState(
    isSupplier() ? 'im-supplier' : 'my-suppliers',
  )

  const filter = useFilter()
  const history = useHistory()
  const classes = useStyles()

  const handleOpenTour = () => setOpenTour(true)

  const handleSelectButton = (type) => {
    if (type === selectedButton) return

    filter.setFilters({})
    setSelectedButton(type)
  }

  const handleReditectToCreate = () => {
    history.push({
      pathname: routes.thirdPartyManagements.partners.new,
      search: `?registerType=${constants.thirdPartyManagements.REGISTER_TYPES.PRE_AVALIATION}`,
    })
  }

  return (
    <Page title="Gestão de Terceiros - Pré-Avaliação">
      <Container maxWidth={false}>
        <ContentHeader title="Pré-Avaliação">
          <Button
            id={constants.tours.thirdPartyManagements.STEPS.PRE_AVALIATION[1]}
            variant="contained"
            color="primary"
            startIcon={<HelpCircleIcon size={20} />}
            onClick={handleOpenTour}
          >
            Tutorial
          </Button>

          <DriveTour
            stepsMatrix={
              constants.tours.thirdPartyManagements.STEPS_OBJECT.PRE_AVALIATION
            }
            open={openTour}
            setOpen={setOpenTour}
          />

          {!isSupplier() && (
            <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
              <Button
                id={
                  constants.tours.thirdPartyManagements.STEPS.PRE_AVALIATION[2]
                }
                variant="contained"
                color="primary"
                onClick={handleReditectToCreate}
              >
                Nova pré-avaliação
              </Button>
            </Permitted>
          )}

          <Filters filter={filter} selectedButton={selectedButton} />
        </ContentHeader>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AdvisorCard
              imageSrc={inviteStreamlineIcon}
              text={constants.thirdPartyManagements.BANNERS.PRE_AVALIATION}
            />
          </Grid>

          {!isSupplier() && (
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gridGap={theme.spacing(3)}
              >
                <FatButton
                  id={
                    constants.tours.thirdPartyManagements.STEPS
                      .PRE_AVALIATION[3]
                  }
                  className={classes.button}
                  variant={
                    selectedButton === 'my-suppliers' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectButton('my-suppliers')}
                >
                  Meus Pré-Avaliados
                </FatButton>
                <FatButton
                  id={
                    constants.tours.thirdPartyManagements.STEPS
                      .PRE_AVALIATION[4]
                  }
                  className={classes.button}
                  variant={
                    selectedButton === 'im-supplier' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectButton('im-supplier')}
                >
                  Sou Pré-Avaliado
                </FatButton>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            {selectedButton === 'my-suppliers' ? (
              <PreAvaliationTable filter={filter} />
            ) : (
              <ImPartnerTable preAvaliation filter={filter} />
            )}
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementPreAvaliation
