import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Box, Button } from '@material-ui/core'
import {
  Mail as MailIcon,
  Send as SendIcon,
  HelpCircle as HelpCircleIcon,
} from 'react-feather'

import {
  Container,
  ContentHeader,
  DriveTour,
  Page,
  Permitted,
} from 'components'
import { FatButton, Footer, AdvisorCard } from './components'
import { Filters, ReceivedTable, SentTable } from './components/invite'

import useFilter from 'hooks/useFilter'
import usePermissions from 'hooks/usePermissions'

import constants from 'constants/index'
import inviteStreamlineIcon from 'images/invite_streamline_icon.svg'
import theme from 'theme'
import useStyles from './components/invite/styles'
import permissions from 'constants/permissions'
import { routes } from 'Routes'

const ThirdPartyManagementInvite = () => {
  const { isSupplier } = usePermissions()
  const history = useHistory()

  const [selectedButton, setSelectedButton] = useState(
    isSupplier() ? 'received' : 'sent',
  )
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()
  const filter = useFilter()

  const handleSelectButton = (type) => {
    if (type === selectedButton) return

    filter.setFilters({})
    setSelectedButton(type)
  }

  const handleOpenTour = () => setOpenTour(true)

  const handleRedirectToCreation = () => {
    history.push(routes.thirdPartyManagements.partners.new)
  }

  return (
    <Page title="Gestão de Terceiros - Convites">
      <Container maxWidth={false}>
        <ContentHeader title="Convites">
          {!isSupplier() && (
            <Button
              id={constants.tours.thirdPartyManagements.STEPS.INVITES[1]}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
            >
              Tutorial
            </Button>
          )}

          {!isSupplier() && (
            <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRedirectToCreation}
              >
                Novo Parceiro
              </Button>
            </Permitted>
          )}

          <DriveTour
            stepsMatrix={
              constants.tours.thirdPartyManagements.STEPS_OBJECT.INVITES
            }
            open={openTour}
            setOpen={setOpenTour}
          />

          <Filters filter={filter} selectedButton={selectedButton} />
        </ContentHeader>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AdvisorCard
              imageSrc={inviteStreamlineIcon}
              text={constants.thirdPartyManagements.BANNERS.INVITE}
            />
          </Grid>

          {!isSupplier() && (
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gridGap={theme.spacing(3)}
              >
                <FatButton
                  id={constants.tours.thirdPartyManagements.STEPS.INVITES[2]}
                  startIcon={<SendIcon size={20} />}
                  className={classes.button}
                  variant={selectedButton === 'sent' ? 'contained' : 'outlined'}
                  onClick={() => handleSelectButton('sent')}
                >
                  Enviados
                </FatButton>
                <FatButton
                  id={constants.tours.thirdPartyManagements.STEPS.INVITES[3]}
                  startIcon={<MailIcon size={20} />}
                  className={classes.button}
                  variant={
                    selectedButton === 'received' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectButton('received')}
                >
                  Recebidos
                </FatButton>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            {selectedButton === 'sent' ? (
              <SentTable filter={filter} />
            ) : (
              <ReceivedTable filter={filter} />
            )}
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementInvite
