import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import { useMsal } from '@azure/msal-react'
import { BrowserAuthError } from '@azure/msal-browser'

import useSnackbar from 'hooks/useSnackbar'

import microsoftIcon from 'images/microsoft.png'
import useStyles from './styles'
import { LoadingFeedback } from 'components'
import loginRequest from 'helpers/microsoftLogin/loginRequest'
import defineErrorMessage from 'helpers/formatters/definedErrorMessage'

const MicrosoftSignInButton = () => {
  const [loading, setLoading] = useState(false)

  const { instance } = useMsal()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const loginMicrosoft = async () => {
    try {
      setLoading(true)
      await instance.loginRedirect(loginRequest)
    } catch (error) {
      if (
        error instanceof BrowserAuthError &&
        error.errorCode === 'user_cancelled'
      ) {
        // Usuário cancelou o fluxo, não faça nada
        return
      }
      snackbar.open({
        message: defineErrorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <IconButton onClick={loginMicrosoft} className={classes.buttonLogin}>
        <img src={microsoftIcon} alt="Microsoft Icon" width={22} height={22} />
      </IconButton>
    </>
  )
}

export default MicrosoftSignInButton
