import React, { useRef, useState } from 'react'
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core'
import { LogOut as LogOutIcon } from 'react-feather'
import { LabelIconStart } from 'components'

import styles from './styles'

import * as service from 'service'
import useAuth from 'hooks/useAuth'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

const useStyles = makeStyles(styles)

const Account = () => {
  const classes = useStyles()
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const ref = useRef(null)
  const auth = useAuth()
  const [isOpen, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    if (isAuthenticated) {
      instance.logoutRedirect({
        onRedirectNavigate: () => {
          return false
        },
      })
    }
    service.dponet.auth.logout()
    auth.loadOut()
  }

  const avatarLetters = ((auth.user && auth.user.name) || 'Usuário')
    .split(' ')
    .map((word) => word[0])
    .join('')

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        mb={1}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar className={classes.avatar}>
          {avatarLetters[0]}
          {avatarLetters[avatarLetters.length - 1]}
        </Avatar>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        classes={{ list: classes.menuList }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <Box ml={1} my={1} display="flex">
          <Typography variant="subtitle1">Perfil</Typography>
        </Box>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <Box my={1}>
            <LabelIconStart
              variant="inherit"
              icon={<LogOutIcon size="20" />}
              label="Sair"
            />
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}

export default Account
