import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { AuditsPageStructure, AuditsActions } from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const AuditsNew = ({ match }) => {
  const { auditConfigId } = match?.params

  const [loading, setLoading] = useState(false)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.auditConfigs.show,
    { auditConfigId },
    [],
  )

  const auditConfig = response?.data?.auditConfig
  const companyAudit = auditConfig?.companyAudit

  return (
    <Page title="Automação - Auditoria de processos">
      <LoadingFeedback open={isLoading || loading} />
      <Container maxWidth={false}>
        <ContentHeader title="Configurar automação" breakLine={false}>
          <AuditsActions
            companyAudit={companyAudit}
            setLoading={setLoading}
            refresh={refresh}
          />
        </ContentHeader>
        {!isLoading && !isEmpty(auditConfig) && (
          <AuditsPageStructure
            formRef="automation-audits-info-section-form"
            isAutomation
            setLoading={setLoading}
            companyAudit={companyAudit}
            automationData={{
              periodType: auditConfig?.periodType,
              recurrence: auditConfig?.recurrence,
            }}
            auditConfigId={auditConfigId}
            refresh={refresh}
          />
        )}
      </Container>
    </Page>
  )
}

export default AuditsNew
