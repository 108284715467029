import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useForm, FormContext } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'

import {
  AutocompleteTypeAndSearch,
  BaseDialog,
  LoadingFeedback,
} from 'components'

import * as service from 'service'
import helpers from 'helpers'

import schema from './schema'

const AddDialog = ({
  open = false,
  setOpen = () => {},
  incidentId,
  onEvent = () => {},
  dataProcessesId = [],
}) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const optionalForm = useForm({
    validationSchema: schema,
    defaultValues: {
      dataProcess: { id: null, name: '' },
    },
  })

  const fetchData = async (inputValue) => {
    try {
      const response = await service.dponet.dataProcesses.get({
        name: inputValue,
        perPage: 10000000,
        notInIds: dataProcessesId,
      })

      const proceses = response?.data?.dataProcesses

      return proceses?.map((dataProcess) => {
        return {
          id: dataProcess?.id,
          name: `${dataProcess?.name} - (${dataProcess?.department?.name})`,
        }
      })
    } catch {
      return []
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await service.dponet.incidents.linkProcess({
        incidentId,
        dataProcessId: data.dataProcess.id,
      })

      snackbar.open({
        message: 'Processo vinculado com sucesso',
        variant: 'success',
      })

      onEvent()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao vincular processo',
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <>
      <LoadingFeedback isLoading={loading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Víncular processo"
        closeButtonText="Cancelar"
        actionButtonText="Víncular"
        actionButton={optionalForm.handleSubmit(onSubmit)}
        dialogSize="md"
        loading={loading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormContext {...optionalForm}>
              <AutocompleteTypeAndSearch
                textField={{
                  label: 'Selecione o processo que deseja vincular',
                  variant: 'outlined',
                }}
                requestToLoadOptions={fetchData}
                name="dataProcess"
                error={!!optionalForm?.errors?.dataProcess}
                helperText={optionalForm?.errors?.dataProcess?.message}
              />
            </FormContext>
          </Grid>
        </Grid>
      </BaseDialog>
    </>
  )
}

AddDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  incidentId: PropTypes.number,
  onEvent: PropTypes.func,
  dataProcessesId: PropTypes.array,
}

export default AddDialog
