import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { AuditsPageStructure, AuditsActions } from './components'

import useFetch from 'hooks/useFetch'

import constants from 'constants/index'

import * as service from 'service'

const AuditsShow = ({ match }) => {
  const { auditId } = match?.params
  const [loading, setLoading] = useState(false)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.companyAudits.show,
    {
      auditId,
    },
  )

  const companyAudit = response?.data?.companyAudit

  const isFinalized =
    companyAudit?.status === constants.audits.FINALIZED_STATUS_ID

  return (
    <Page title="Visualizar - Auditoria de processos">
      <LoadingFeedback open={isLoading || loading} />
      <Container maxWidth={false}>
        <ContentHeader title="Visualizar auditoria" breakLine={false}>
          {!isFinalized && (
            <AuditsActions
              companyAudit={companyAudit}
              setLoading={setLoading}
              refresh={refresh}
            />
          )}
        </ContentHeader>
        {!isLoading && !isEmpty(companyAudit) && (
          <AuditsPageStructure
            companyAudit={companyAudit}
            formType="show"
            setLoading={setLoading}
            refresh={refresh}
          />
        )}
      </Container>
    </Page>
  )
}

export default AuditsShow
