import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  addUrlButton: {
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
}))

export default styles
