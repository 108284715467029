import axios from 'axios'

import api from 'service/api'
import { getBaseURL } from 'service/env'
import * as service from 'service'

const dponetAPI = api.create('cookies', false)
const dponetAPIWithTreatError = api.create('cookies')
const dpoeasyURL = getBaseURL('cookies')

const checkUrl = async (params) =>
  await dponetAPI.get('/banners/check_url', { params })

const compile = async ({ bannerId }) =>
  await dponetAPI.post(`/banners/${bannerId}/compile`)

const create = async (data) => await dponetAPI.post('/banners', data)

const destroy = async (bannerId) =>
  await dponetAPI.delete(`/banners/${bannerId}`)

const get = async ({ bannerId = '', ...params }) =>
  await dponetAPIWithTreatError.get(`/banners/${bannerId}`, { params })

const scanner = async ({ bannerId }) =>
  await dponetAPI.get(`/banners/${bannerId}/scanner`)

const scannerByToken = async (token) =>
  await dponetAPI.get('/banners/scanner_by_token', { params: { token } })

const update = async ({ bannerId = '', banner }) =>
  await dponetAPI.put(`/banners/${bannerId}`, { banner })

const uploadLogo = async ({ bannerId = '', logo }) => {
  const data = new FormData()

  logo && data.append('logo', logo)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.cookies.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${dpoeasyURL}/banners/${bannerId}/upload_logo`,
    data: data,
    ...config,
  })
}

export default {
  checkUrl,
  compile,
  create,
  destroy,
  get,
  scanner,
  scannerByToken,
  update,
  uploadLogo,
}
