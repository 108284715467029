import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import {
  Layers as LayersIcon,
  File as FileIcon,
  Package as PackageIcon,
  HelpCircle as HelpCircleIcon,
} from 'react-feather'

import { Grid, Tooltip, Box, Button } from '@material-ui/core'
import { DataUsage as DataUsageIcon } from '@material-ui/icons'

import helpers from 'helpers'
import constants from 'constants/index'
import {
  Container,
  ContentHeader,
  LoadingFeedback,
  DriveTour,
} from 'components'

import {
  DashboardTabs,
  DashboardMain,
  DashboardProcess,
  DashboardSupplier,
  DashboardDiagnostic,
} from './components'

const DashboardGraphs = ({
  gapStats,
  processStats,
  generalStats,
  supplierStats,
  fragilityStats,
  questionnaires,
  questionControlStats,
  loading = false,
}) => {
  const [openTour, setOpenTour] = useState(false)
  const [currentTab, setCurrentTab] = useState(
    constants.myLgpd.DASHBOARD_MAIN_TAB,
  )

  const tabs = [
    {
      value: constants.myLgpd.DASHBOARD_MAIN_TAB,
      label: 'TODOS',
      icon: <DataUsageIcon fontSize="inherit" />,
    },
    {
      value: constants.myLgpd.DASHBOARD_PROCESS_TAB,
      label: 'PROCESSOS',
      icon: <LayersIcon size={16} />,
    },
    {
      value: constants.myLgpd.DASHBOARD_DIAGNOSTIC_TAB,
      label: 'DIAGNÓSTICO',
      icon: <FileIcon size={16} />,
    },
    {
      value: constants.myLgpd.DASHBOARD_SUPPLIER_TAB,
      label: 'PARCEIROS',
      icon: <PackageIcon size={16} />,
    },
  ]

  const handleTabsChange = (_, value) => {
    const { label } = tabs.find((tab) => tab.value === value)
    helpers.mixpanel.track('Dashboard', label, {
      action: `${label.toLowerCase()}-dashboard`,
    })

    setCurrentTab(value)
  }

  const handleOpenTour = () => {
    const where = 'Dashboard'
    const title = 'Tour'

    const params = {
      action: 'tour-dashboard',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const validationSteps = constants.myLgpd.DASHBOARD_DRIVER_STEPS_OBJECT

  return (
    <Container
      maxWidth={false}
      spacingXl={15}
      spacingLg={10}
      spacingMd={5}
      spacingSm={5}
    >
      <ContentHeader title="Dashboard">
        <Tooltip title="Iniciar o tour guiado">
          <Button
            id={constants.myLgpd.DASHBOARD_DRIVER_STEP_1}
            variant="contained"
            color="primary"
            startIcon={<HelpCircleIcon size={20} />}
            onClick={handleOpenTour}
            fullWidth
          >
            Tutorial
          </Button>
        </Tooltip>
      </ContentHeader>

      <LoadingFeedback open={loading} />
      <Grid container spacing={3}>
        <DashboardTabs
          tabs={tabs}
          handleTabsChange={handleTabsChange}
          currentTab={currentTab}
        />
        {currentTab === constants.myLgpd.DASHBOARD_MAIN_TAB && (
          <DashboardMain
            gapStats={gapStats}
            processStats={processStats}
            generalStats={generalStats}
            supplierStats={supplierStats}
            questionnaires={questionnaires}
            fragilityStats={fragilityStats}
            questionControlStats={questionControlStats}
            loading={loading}
          />
        )}
        {currentTab === constants.myLgpd.DASHBOARD_PROCESS_TAB && (
          <DashboardProcess processStats={processStats} loading={loading} />
        )}
        {currentTab === constants.myLgpd.DASHBOARD_DIAGNOSTIC_TAB &&
          !isEmpty(fragilityStats) && (
            <DashboardDiagnostic
              gapStats={gapStats}
              questionnaires={questionnaires}
              fragilityStats={fragilityStats}
              questionControlStats={questionControlStats}
            />
          )}
        {currentTab === constants.myLgpd.DASHBOARD_SUPPLIER_TAB && (
          <DashboardSupplier supplierStats={supplierStats} loading={loading} />
        )}
      </Grid>
      <DriveTour
        stepsMatrix={validationSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Container>
  )
}

DashboardGraphs.propTypes = {
  gapStats: PropTypes.object.isRequired,
  processStats: PropTypes.object.isRequired,
  generalStats: PropTypes.object.isRequired,
  supplierStats: PropTypes.object.isRequired,
  fragilityStats: PropTypes.object.isRequired,
  questionnaires: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default DashboardGraphs
