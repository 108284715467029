const KIND_TAG = {
  RIPD: 'ripd',
  ROPA: 'ropa',
  COMPLIANCE: 'compliance',
  MANAGEMENT: 'management',
  CONTROL: 'control',
  FRAGILITY: 'fragility',
  TRAINING: 'training',
}

const KIND = {
  RIPD: 'ripd',
  ROPA: 'ropa',
  COMPLIANCE: 'compliance',
  MANAGEMENT: 'management',
  CONTROL: 'control',
  FRAGILITY: 'fragility',
  TRAINING: 'training',
  LIA: 'lia',
  CONSENT: 'consent',
  AUDIT: 'audit',
}

const DOWNLOAD_ONLY_ID_ACTION = 1
const DESTROY_VERSION_ID_ACTION = 2
const VERSION_EXISTS_ACTION_OPTIONS = [
  {
    id: DOWNLOAD_ONLY_ID_ACTION,
    name: 'Apenas baixar um novo relatório',
  },
  {
    id: DESTROY_VERSION_ID_ACTION,
    name: 'Excluir versão atual e gerar uma nova versão',
  },
]

const reportVersions = {
  DESTROY_VERSION_ID_ACTION,
  KIND_TAG,
  KIND,
  VERSION_EXISTS_ACTION_OPTIONS,
  DOWNLOAD_ONLY_ID_ACTION,
}

export default reportVersions
