import React from 'react'
import PropTypes from 'prop-types'

import { BarChart, DashboardChartPaper } from 'components'
import { routes } from 'Routes'
import theme from 'theme'

const FragilitiesByOcurrenceLocation = ({
  title,
  subtitle,
  redirect = routes.companyFragilities.all,
  values = {},
  ...rest
}) => {
  return (
    <DashboardChartPaper
      title={title}
      subtitle={subtitle}
      redirect={redirect}
      {...rest}
    >
      <BarChart
        id="fragilities-by-ocurrence-location"
        colors={[theme.palette.primary.main]}
        series={[{ name: 'Ameaças', data: values.values }]}
        names={['Empresa', 'Departamentos', 'Processos', 'Nenhum local']}
        heightValue={255}
        opacity={1}
        enabledTotal
      />
    </DashboardChartPaper>
  )
}

FragilitiesByOcurrenceLocation.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  redirect: PropTypes.string,
  values: PropTypes.object,
}

export default FragilitiesByOcurrenceLocation
