import React, { useEffect, useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Box, Container, Button, Tooltip } from '@material-ui/core'

import { Page, ContentHeader, LoadingFeedback, DriveTour } from 'components'
import { CompanyFragilityForm } from './components'

import useCompanyFragility from 'hooks/useCompanyFragility'

import useStyles from './styles'
import constants from 'constants/index'
import helpers from 'helpers'

const CompanyFragilityNew = ({ match }) => {
  const [openTour, setOpenTour] = useState(false)

  const { companyFragilityId } = match.params
  const { loading, loadCompanyFragility } = useCompanyFragility()
  const classes = useStyles()

  useEffect(() => {
    loadCompanyFragility(companyFragilityId)
    //eslint-disable-next-line
  }, [])

  const handleOpenTour = () => {
    const where = 'Ameaças'
    const title = 'Tour'

    const params = {
      action: 'tour-editar-ameaça-diagnostico',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Editar ameaça">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Editar ameaça">
          <Tooltip title="Iniciar o tour guiado">
            <Button
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
            >
              Tutorial
            </Button>
          </Tooltip>
        </ContentHeader>
        <Box width="100%" mt={1}>
          {!loading && <CompanyFragilityForm />}
        </Box>
      </Container>

      <LoadingFeedback open={loading} />
      <DriveTour
        stepsMatrix={constants.fragilities.NEW_FRAGILITIES_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default CompanyFragilityNew
