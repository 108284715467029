import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  User as UserIcon,
  Briefcase as BriefcaseIcon,
  DollarSign as DollarSignIcon,
} from 'react-feather'
import clsx from 'clsx'
import { Box, Button, Hidden } from '@material-ui/core'

import {
  Container,
  Page,
  ContentHeader,
  MenuButton,
  DialogLogs,
  Permitted,
} from 'components'
import Account from './Account'
import Company from './Company'
import Financial from './Financial'
import { MissingDpoAlert, PendingDpoAlert } from './components'

import useAuth from 'hooks/useAuth'
import usePermissions from 'hooks/usePermissions'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

const SettingsMain = () => {
  const permissions = usePermissions()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { company, homeRoute } = useAuth()

  const accountTabValue = 'account'
  const companyTabValue = 'company'
  const financialTabValue = 'financial'

  const [currentTab, setCurrentTab] = useState(
    location?.state?.tab || accountTabValue,
  )
  const [logs, setLogs] = useState([])
  const [loadingLogs, setLoadingLogs] = useState([])
  const [openLogs, setOpenLogs] = useState(false)

  const isActive = (tab) => tab === currentTab

  const activeStyles = {
    color: 'primary',
  }

  // Exibir edição de empresa se:
  // Bloqueio de edição dos dados cadastrias esta inativo
  // Se o perfil possui permissão
  // E a empresa não é template

  const showCompanyTab =
    !helpers.company.isTemplate(company) &&
    !company.blockUpdate &&
    permissions.permitted(constants.permissions.COMPANIES.UPDATE_SELF)

  useEffect(() => {
    const loadLogs = async () => {
      setLoadingLogs(true)
      if (!permissions.permitted(constants.permissions.COMPANIES.UPDATE_SELF)) {
        setLoadingLogs(false)
        return
      }
      const response = await service.dponet.companies.logs({
        companyId: company.id,
      })
      setLogs(response?.data)
      setLoadingLogs(false)
    }
    if (showCompanyTab) {
      loadLogs()
    }

    return () => {}
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (permissions.isDpo()) history.push(homeRoute)
    //eslint-disable-next-line
  }, [])

  return (
    <Page title="Configurações">
      <Container
        className={classes.container}
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={5}
        spacingSm={5}
        spacingXs={2}
      >
        <ContentHeader title="Configurações" xsFull={false} breakLine={false}>
          {isActive(companyTabValue) && (
            <MenuButton iconButtonSize="small" iconButtonFontSize={22}>
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  setOpenLogs(true)
                }}
              >
                Histórico de alterações
              </Button>
            </MenuButton>
          )}
        </ContentHeader>

        {showCompanyTab &&
        company?.outsourced?.status ===
          constants.outsourcedDpo.PENDING_STATUS ? (
          <PendingDpoAlert />
        ) : (
          company?.outsourced?.status !==
            constants.outsourcedDpo.ACTIVE_STATUS && <MissingDpoAlert />
        )}
        <Box className={classes.containerMenu}>
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
            className={classes.perfectScrollbar}
          >
            <Button
              disableElevation
              startIcon={<UserIcon size={20} />}
              variant="contained"
              onClick={() => setCurrentTab(accountTabValue)}
              {...(isActive(accountTabValue) && activeStyles)}
              className={clsx(classes.button, {
                [classes.tabRightAndLeft]: !showCompanyTab,
                [classes.tabLeft]: showCompanyTab,
              })}
            >
              <Hidden smDown>Dados pessoais</Hidden>
            </Button>
            {showCompanyTab && (
              <Button
                disableElevation
                startIcon={<BriefcaseIcon size={20} />}
                variant="contained"
                onClick={() => setCurrentTab(companyTabValue)}
                {...(isActive(companyTabValue) && activeStyles)}
                className={clsx(classes.button)}
              >
                <Hidden smDown>Dados da empresa</Hidden>
              </Button>
            )}
            <Permitted tag={constants.permissions.PAYMENT_ORDERS.LIST}>
              <Button
                disableElevation
                startIcon={<DollarSignIcon size={20} />}
                variant="contained"
                onClick={() => setCurrentTab(financialTabValue)}
                {...(isActive(financialTabValue) && activeStyles)}
                className={clsx(classes.button, classes.tabRight)}
              >
                <Hidden smDown>Dados financeiros</Hidden>
              </Button>
            </Permitted>
          </PerfectScrollbar>
        </Box>
        {isActive(accountTabValue) && <Account />}
        {showCompanyTab && isActive(companyTabValue) && <Company />}
        {isActive(financialTabValue) && <Financial />}
      </Container>
      {!loadingLogs && (
        <DialogLogs
          logs={logs?.dponetAudits}
          open={openLogs}
          setOpen={setOpenLogs}
        />
      )}
    </Page>
  )
}

export default SettingsMain
