import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { Card } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

const QuestionnaireDetail = ({ questionnaire }) => {
  const complianceQuestions = questionnaire?.complianceQuestions
  const noncomplianceQuestions = questionnaire?.noncomplianceQuestions

  const answeredQuestionText = (question) => {
    if (question.status === constants.questions.ANSWERED_STATUS) {
      if (question.user?.name) {
        return question.user.name
      }

      if (
        questionnaire.kind === constants.questionnaire.FUNDAMENTAL_REQUIREMENTS
      ) {
        return 'Plataforma'
      }
    }
    return 'Não informado'
  }

  const updatedAtQuestionText = (question) => {
    if (question.status === constants.questions.ANSWERED_STATUS) {
      if (question.updatedAt) {
        return helpers.formatters.date(question.updatedAt)
      }
    }
    return 'Não informado'
  }

  return (
    <Box>
      {isEmpty(questionnaire) ? (
        <Typography variant="button">
          Nenhum questionário selecionado
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card title="Medidas Adotadas">
              <Grid container spacing={1}>
                {isEmpty(complianceQuestions) ? (
                  <Grid item xs={12}>
                    <Typography variant="button">
                      Nenhuma medida adotada
                    </Typography>
                  </Grid>
                ) : (
                  complianceQuestions.map((complianceQuestion) => (
                    <Grid item xs={12}>
                      <Box border="1px solid #e0e0e0" p={2} borderRadius={2}>
                        <Typography variant="h6" gutterBottom>
                          {complianceQuestion.theme}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {complianceQuestion.affirmative ||
                            complianceQuestion.question}
                        </Typography>
                        <Typography variant="body2">
                          Respondido por&nbsp;
                          {answeredQuestionText(complianceQuestion)} em&nbsp;
                          {updatedAtQuestionText(complianceQuestion)}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card title="Medidas Não Adotadas">
              <Grid container spacing={1}>
                {isEmpty(noncomplianceQuestions) ? (
                  <Grid item xs={12}>
                    <Typography variant="button">
                      Nenhuma medida não adotada
                    </Typography>
                  </Grid>
                ) : (
                  noncomplianceQuestions.map((noncomplianceQuestion) => (
                    <Grid item xs={12}>
                      <Box border="1px solid #e0e0e0" p={2} borderRadius={2}>
                        <Typography variant="h6" gutterBottom>
                          {noncomplianceQuestion.theme}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {noncomplianceQuestion.affirmative ||
                            noncomplianceQuestion.question}
                        </Typography>
                        <Typography variant="body2">
                          Respondido por&nbsp;
                          {answeredQuestionText(noncomplianceQuestion)} em&nbsp;
                          {updatedAtQuestionText(noncomplianceQuestion)}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default QuestionnaireDetail
