import React, { useRef, useState } from 'react'

import {
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  makeStyles,
  Typography,
  Divider,
  Link,
  Hidden,
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import {
  LogOut as LogOutIcon,
  Layers as LayersIcon,
  Settings as SettingsIcon,
} from 'react-feather'
import { LabelIconStart, UserAvatar, UserBox } from 'components'

import styles from './styles'

import { routes } from 'Routes'

import * as service from 'service'
import useAuth from 'hooks/useAuth'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

const useStyles = makeStyles(styles)

const Account = () => {
  const classes = useStyles()
  const ref = useRef(null)
  const auth = useAuth()
  const [isOpen, setOpen] = useState(false)
  const history = useHistory()
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    if (isAuthenticated) {
      instance.logoutRedirect({
        onRedirectNavigate: () => {
          return false
        },
      })
    }
    service.dponet.auth.logout()
    service.dponet.auth.clearStorage()
    auth.loadOut()
  }

  const handleSettings = () => {
    history.push(routes.settings)
  }

  const redirectToTerms = () => {
    window.open('https://www.dponet.com.br/termos-de-uso/')
  }

  const redirectToPrivacy = () => {
    window.open('https://www.dponet.com.br/politica-de-privacidade/')
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <UserAvatar />

        <Hidden smDown>
          <UserBox />
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        classes={{ list: classes.menuList }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <Box ml={1} my={1} display="flex">
          <Typography variant="subtitle1">Perfil</Typography>
        </Box>
        <Divider />
        <MenuItem onClick={handleSettings}>
          <Box my={1}>
            <LabelIconStart
              variant="inherit"
              icon={<SettingsIcon size="20" />}
              label="Configurações"
            />
          </Box>
        </MenuItem>
        <Box>
          <MenuItem onClick={auth.handleSelectCompany}>
            <Box my={1}>
              <LabelIconStart
                variant="inherit"
                icon={<LayersIcon size="20" />}
                label="Trocar de empresa"
              />
            </Box>
          </MenuItem>
        </Box>
        <MenuItem onClick={handleLogout}>
          <Box my={1}>
            <LabelIconStart
              variant="inherit"
              icon={<LogOutIcon size="20" />}
              label="Sair"
            />
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link className={classes.pointer} onClick={redirectToTerms}>
            Termos e condições de uso
          </Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.pointer} onClick={redirectToPrivacy}>
            Aviso de privacidade
          </Link>
        </MenuItem>
      </Menu>
    </>
  )
}

export default Account
