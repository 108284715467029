import helpers from 'helpers'
import { isNil } from 'lodash'

const dpoDataCompany = (company) => {
  if (isNil(company.dpoData)) {
    return 'O [COLOQUE O NOME DO DPO], [COLOQUE O EMAIL DO DPO]'
  }

  if (company.dpoData.dponetIsDpo) {
    return `O ${company.dpoData.name}, inscrito no ${
      company?.cnpj ? 'CNPJ' : 'CPF'
    } sob o nº ${company.dpoData.document}`
  }

  return `O(A) ${company.dpoData.name}, ${company.dpoData.email}`
}

const defaultPrivacy = (company) => {
  const address = company.address
  const hasBanner = company.siteUrl

  return `<p dir="ltr" style="text-align: center; line-height: 1.5;"><strong style="font-size: 24px;">AVISO DE PRIVACIDADE DA ${company?.name?.toUpperCase()}</strong></p>
<p dir="ltr" style="line-height: 1.5;"><br></p>
<p dir="ltr" style="text-align: right; line-height: 1.5;"><span style="font-size: 14px;">Última Atualização: ${helpers.formatters.dateLocaleFormat(
    new Date(),
    'DD [de] MMMM [de] YYYY',
  )}</span></p>
<p dir="ltr" style="line-height: 1.5;"><br></p>
<p dir="ltr" style="line-height: 1.5;">Olá! Seja bem-vindo(a) ao portal eletrônico da ${
    company?.name
  }!</p>
<p dir="ltr" style="line-height: 1.5;"><br></p>
<p dir="ltr" style="line-height: 1.5;">Inicialmente, agradecemos muito a sua visita! Saiba que, quando você ingressa em nosso portal, nos confia seus dados e informações pessoais. Por isso, nos comprometemos a manter essa confiança.</p>
<p dir="ltr" style="line-height: 1.5;">Nosso Aviso de Privacidade explicará a você, de forma clara e acessível, quais serão seus dados pessoais coletados, com quem serão compartilhados e em que locais serão armazenados.</p>
<p dir="ltr" style="line-height: 1.5;">${dpoDataCompany(
    company,
  )}, foi nomeado como nosso Encarregado(a) de Dados. Assim, está apto a exercer todas as funções e atribuições do cargo, previstas na Lei Geral de Proteção de Dados Pessoais (LGPD).</p>
<p dir="ltr" style="line-height: 1.5;"><strong>Caso você deseje exercer qualquer um de seus direitos, expressos na LGPD, basta clicar no Selo 'Canal de Comunicação LGPD', em nosso site, e preencher o 'Formulário de Atendimento ao Titular de Dados'.</strong></p>
<p dir="ltr" style="line-height: 1.5;"><strong>Ao acessar e navegar por nosso portal, você aceita nosso Aviso, estando ciente e em total acordo com a forma como utilizaremos suas informações pessoais.</strong></p>
<p dir="ltr" style="line-height: 1.5;">Para facilitar a sua compreensão, saiba que nosso Aviso de Privacidade está organizado da seguinte forma:</p>
<div dir="ltr" align="left" style="line-height: 1.5;">
  <table style="border-collapse: collapse; width: 38.3761%;">
    <tbody>
      <tr>
        <td style="width: 10.8392%; text-align: center;">1</td>
        <td style="width: 88.986%;">Informações Coletadas<br></td>
      </tr>
      <tr>
        <td style="text-align: center;">2</td>
        <td>Por quê e Como Usamos seus Dados<br></td>
      </tr>
      ${
        hasBanner
          ? `<tr>
          <td style="text-align: center;">3</td>
          <td>Por quê e Como Tratamos Cookies em nosso Sítio Institucional?<br></td>
        </tr>`
          : ''
      }
      <tr>
        <td style="text-align: center;">${hasBanner ? '4' : '3'}</td>
        <td>Compartilhamento das Informações<br></td>
      </tr>
      <tr>
        <td style="text-align: center;">${hasBanner ? '5' : '4'}</td>
        <td>Direitos dos Titulares<br></td>
      </tr>
      <tr>
        <td style="text-align: center;">${hasBanner ? '6' : '5'}</td>
        <td>Segurança das Informações<br></td>
      </tr>
      <tr>
        <td style="text-align: center;">${hasBanner ? '7' : '6'}</td>
        <td>Atualizações do nosso Aviso de Privacidade<br></td>
      </tr>
      <tr>
        <td style="text-align: center;">${hasBanner ? '8' : '7'}</td>
        <td>Legislação Aplicável<br></td>
      </tr>
      <tr>
        <td style="text-align: center;">${hasBanner ? '9' : '8'}</td>
        <td>Contato do Encarregado<br></td>
      </tr>
    </tbody>
  </table>
</div>
<p dir="ltr" style="line-height: 1.5;">&nbsp;<br></p>
<ol style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>INFORMAÇÕES COLETADAS:</strong><br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Neste item, serão apresentadas as principais atividades que envolvem a coleta de suas informações pessoais, de acordo com as principais finalidades, titulares afetados, hipóteses legais e categorias de dados pessoais coletados para que você interaja conosco:&nbsp;</p>
<p dir="ltr" style="text-align: left; line-height: 1.5;"><strong>&nbsp;</strong><br></p>
<ol style="list-style-type: lower-alpha; line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>Informações fornecidas por você:</strong></p>
  </li>
</ol>
<ul style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><span style="text-decoration: underline;">Dados de cadastro: </span>ao se cadastrar em nosso site, você nos fornece informações essenciais para a criação da sua conta.</p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><span style="text-decoration: underline;">Informações de autenticação: </span>para garantir um ambiente de navegação seguro, podemos solicitar informações adicionais de identificação e autenticação.<br><br></p>
  </li>
</ul>
<ol style="list-style-type: lower-alpha; line-height: 1.5;" start="2">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>Informações geradas automaticamente:</strong></p>
  </li>
</ol>
<ul style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><span style="text-decoration: underline;">Registros de acesso:</span> a organização coleta, de forma automática, os registros de acesso à aplicação, que podem incluir o endereço de IP utilizado para acessar nosso site, com data e hora. Esses dados são de coleta obrigatória, conforme determinado pela Lei nº 12.295/2014, também conhecida como Marco Civil da Internet, mas serão fornecidos para terceiros apenas com a sua autorização expressa, ou em razão de decisão judicial.<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><span style="text-decoration: underline;">Comunicação com a organização:</span> quando você se comunica com a organização, coletamos informações sobre a sua comunicação, incluindo metadados como: endereço de IP, data, hora e conteúdo das comunicações, como qualquer informação que você escolha fornecer.<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><span style="text-decoration: underline;">Gerenciamento do sítio eletrônico:</span> para isso, nós utilizamos cookies, isto é, arquivos de texto gerados e armazenados no seu navegador ou aparelho, por sites, aplicativos e anúncios online, conforme exposto no item 3.&nbsp;</p>
  </li>

</ul>
<p dir="ltr" style="line-height: 1.5;"><br></p>
<ol start="2" style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>POR QUE E COMO USAMOS SEUS DADOS?</strong> <br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Nós prezamos muito pela sua privacidade e é justamente por isso que todos os dados e informações sobre você são tratadas com a mais alta confidencialidade, sendo usados somente para os fins aqui descritos, e por você conhecidos e autorizados:</p>

<div dir="ltr" align="left" style="line-height: 1.5;">
  <table style="border-collapse: collapse; width: 77.4978%;">
    <tbody>
      <tr>
        <td style="width: 25%; text-align: center;"><strong>FINALIDADE</strong><br></td>
        <td style="width: 25%; text-align: center;"><strong>TITULARES AFETADOS</strong><br></td>
        <td style="width: 25%; text-align: center;"><strong>HIPÓTESE LEGAL</strong><br></td>
        <td style="width: 25%; text-align: center;"><strong>CATEGORIA DE DADOS PESSOAIS</strong><br></td>
      </tr>
      <tr>
        <td style="width: 25%;">Permitir que você acesse e utilize todas as funcionalidades do nosso portal<br></td>
        <td style="width: 25%;">Titulares e seus representantes legais<br></td>
        <td style="width: 25%;">Legítimo interesse (art. 7º, inciso IX)<br></td>
        <td style="width: 25%;">Cadastrais e de identificação<br></td>
      </tr>
      <tr>
        <td style="width: 25%;">Cumprir obrigações legais (atendimento às requisições do exercício de direitos)<br></td>
        <td style="width: 25%;">Titulares e seus representantes legais<br></td>
        <td style="width: 25%;">Cumprimento de obrigação legal (art. 7º, inciso II)<br></td>
        <td style="width: 25%;">Cadastrais e de identificação;<br><br>Informações sobre interação de titular com agentes de tratamento<br></td>
      </tr>
      <tr>
        <td style="width: 25%;">Enviar mensagens, alertas, notificações e atualizações a respeito de suporte ou serviço prestado, produtos, serviços, promoções, notícias, atualizações, eventos e outros assuntos que você possa ter interesse<br></td>
        <td style="width: 25%;">Titulares e seus representantes legais<br></td>
        <td style="width: 25%;">Legítimo interesse (art. 7º, inciso IX)<br></td>
        <td style="width: 25%;">Cadastrais e de identificação<br></td>
      </tr>
      <tr>
        <td style="width: 25%;">Detecção e prevenção de fraudes, <em>spam</em> e incidentes de segurança<br></td>
        <td style="width: 25%;">Titulares e seus representantes legais<br></td>
        <td style="width: 25%;">Cumprimento de obrigação legal (art. 7º, inciso II)<br></td>
        <td style="width: 25%;">Cadastrais e de identificação<br></td>
      </tr>
      <tr>
        <td style="width: 25%;">Verificar ou autenticar as informações fornecidas por você, inclusive comparando a dados coletados de outras fontes<br></td>
        <td style="width: 25%;">Titulares e seus representantes legais<br></td>
        <td style="width: 25%;">Execução de contratos (art. 7º, inciso V)<br></td>
        <td style="width: 25%;">Cadastrais e de identificação<br></td>
      </tr>
      <tr>
        <td style="width: 25%;">Para qualquer fim que você autorizar, no momento da coleta de dados<br></td>
        <td style="width: 25%;">Titulares e seus representantes legais<br></td>
        <td style="width: 25%;">Consentimento (art. 7º, inciso I)<br></td>
        <td style="width: 25%;">Cadastrais e de identificação<br></td>
      </tr>
    </tbody>
  </table>
</div>

<p dir="ltr" style="line-height: 1.5;">Poderemos, eventualmente, utilizar dados para finalidades diversas das previstas neste Aviso de Privacidade, mas todas elas estarão dentro das suas legítimas expectativas. <strong>O eventual uso dos seus dados para finalidades que não cumpram com essa prerrogativa será feito mediante sua autorização prévia.</strong>&nbsp;</p>
<p dir="ltr" style="text-align: left; line-height: 1.5;"><strong>&nbsp;</strong><br></p>
<ol style="list-style-type: lower-alpha; line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>Armazenamento dos dados pessoais:</strong>&nbsp;</p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Nós armazenamos seus dados pessoais de forma segura, conforme padrões de segurança aplicáveis à hipótese, e de maneira que favoreça os meios para o exercício de seus direitos previstos na LGPD.</p>
<p dir="ltr" style="text-align: left; line-height: 1.5;"><strong>&nbsp;</strong><br></p>
<ol style="list-style-type: lower-alpha; line-height: 1.5;" start="2">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>Exclusão dos dados pessoais:&nbsp;</strong></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Todos os dados pessoais coletados serão excluídos de nossos servidores mediante sua requisição, por procedimento gratuito e facilitado, ou quando estes deixarem de ser necessários ou relevantes para o oferecimento de nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da organização, nos termos dos artigos 15 e 16, da LGPD.</p>

<p dir="ltr" style="line-height: 1.5;">&nbsp;<br></p>
${
  hasBanner
    ? `
  <ol start="3" style="line-height: 1.5;">
    <li dir="ltr" aria-level="1" style="line-height: 1.5;">
      <p dir="ltr" role="presentation"><strong>POR QUE E COMO TRATAMOS COOKIES EM NOSSO SÍTIO INSTITUCIONAL?</strong></p>
    </li>
  </ol>
  <p dir="ltr" style="line-height: 1.5;">Para melhorar a sua experiência em nosso sítio eletrônico e prover serviços personalizados, utilizamos cookies. Com exceção dos cookies necessários, indispensáveis para o funcionamento do site, a instalação dos demais tipos de cookies está sujeita ao seu consentimento! Dessa forma, você pode rever suas permissões, podendo aceitá-las, alterá-las ou cancelá-las a qualquer momento. <br>Nosso site não faz a coleta automática dos cookies. Contudo, você não é obrigado a autorizar a utilização de todos eles. No entanto, saiba que nosso site pode não funcionar corretamente se todos os cookies forem desativados. Por esse motivo, caso queira ter uma experiência completa da nossa plataforma online, sugerimos que autorize a coleta de todos os cookies. </p>
  <p dir="ltr" style="text-align: left; line-height: 1.5;"><strong>&nbsp;</strong><br></p>`
    : ''
}
<ol start=${hasBanner ? '4' : '3'} style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>COMPARTILHAMENTO DAS INFORMAÇÕES:</strong><br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Todas as informações que armazenamos sobre você podem ser consideradas ativos no caso de negociações em que a organização fizer parte. Nesse sentido, nos reservamos no direito de, por exemplo, incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra. <strong>Por meio deste Aviso de Privacidade, você está ciente desta possibilidade.</strong>&nbsp;</p>
<p dir="ltr" style="line-height: 1.5;">A organização deverá fornecer seus dados e informações, caso seja requisitado por meio de decisão judicial.</p>
<p dir="ltr" style="line-height: 1.5;">Podemos compartilhar seus dados pessoais com organizações públicas ou privadas, respeitando as disposições da LGPD, em especial o princípio da necessidade, e sempre de forma compatível com a(s) finalidade(s) para a(s) qual(is) foram coletados.&nbsp;</p>
<p dir="ltr" style="line-height: 1.5;">Ao compartilharmos seus dados pessoais com operadores de dados, exigiremos que seus dados sejam tratados de acordo com nossas instruções, o que inclui o armazenamento seguro, sua retenção tão somente pelo período instruído e o não compartilhamento subsequente com outras organizações sem nossa prévia e expressa autorização.</p>
<p dir="ltr" style="text-align: left; line-height: 1.5;"><strong>&nbsp;</strong><br></p>

<ol start=${hasBanner ? '5' : '4'} style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>DIREITOS DOS TITULARES:</strong><br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">A LGPD estabelece que o titular tem o direito de obter do controlador, em relação às suas informações pessoais por ele tratados, a qualquer momento e mediante requisição:</p>
<ul style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">A confirmação da existência de tratamento;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Acesso aos dados;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Correção de dados incompletos, inexatos ou desatualizados;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Anonimização, bloqueio ou eliminação de dados desnecessários ou tratados em desconformidade com a Lei;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Portabilidade dos dados;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Eliminação dos dados pessoais tratados com o seu consentimento;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Informações das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Informações sobre a possibilidade de não fornecer seu consentimento e as consequências da negativa;<br></p>
  </li>
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation">Revogação do consentimento.<br><br></p>
  </li>
</ul>
<p dir="ltr" style="line-height: 1.5;">Caso o titular de dados queira exercer qualquer um de seus direitos expressos na Lei, bem como tirar dúvidas referentes a esta documentação, basta clicar no <strong>Selo "Canal de Comunicação LGPD"</strong> em nosso site e preencher o <strong>"Formulário de Atendimento ao Titular de Dados".</strong></p>
<p dir="ltr" style="line-height: 1.5;">Por meio do Selo, qualquer interessado é direcionado ao Portal Privacidade&amp;Você e pode verificar a autenticidade da adequação da empresa à LGPD, inclusive, podendo verificar: a última revisão de seus mecanismos de controle com a exibição da data e hora em que foi feita a revisão; as políticas de privacidade da empresa; o meio de contato dos titulares de dados; e o meio de contato da ANPD.</p>
<p dir="ltr" style="line-height: 1.5;">&nbsp;<br></p>
<ol start=${hasBanner ? '6' : '5'} style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>SEGURANÇA DAS INFORMAÇÕES:</strong><br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Todos os seus dados são confidenciais e somente pessoas autorizadas terão acesso a eles. Nós empenhamos todos os esforços razoáveis de mercado para garantir a segurança dos nossos sistemas e dos seus dados. Nossos servidores estão localizados em diferentes locais, para garantir estabilidade e segurança, podendo somente ser acessados por meio de canais de comunicação previamente autorizados.</p>
<p dir="ltr" style="line-height: 1.5;">Todas as suas informações serão, sempre que possível, criptografadas, caso não inviabilizem o seu uso pela plataforma. A qualquer momento você poderá requisitar cópia dos seus dados armazenados em nossos sistemas. Manteremos os dados e informações somente até quando estas forem necessárias ou relevantes para as finalidades descritas neste Aviso de Privacidade, ou em caso de períodos pré-determinados por Lei, ou até quando estas forem necessárias para a manutenção de interesses legítimos da organização.</p>
<p dir="ltr" style="line-height: 1.5;">É importante esclarecer que não temos como garantir que todos os seus dados em nossa plataforma estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das credenciais necessárias para acessar o nosso aplicativo.</p>
<p dir="ltr" style="line-height: 1.5;">Por isso, recomendamos que você mantenha sua senha de acesso em local seguro, sendo desaconselhado o compartilhamento desta com terceiros. você se compromete a notificar a organização, imediatamente, através de meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.</p>
<p dir="ltr" style="line-height: 1.5;">&nbsp;<br></p>
<ol start=${hasBanner ? '7' : '6'} style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>ATUALIZAÇÕES DO NOSSO AVISO DE PRIVACIDADE:</strong><br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Nos reservamos no direito de atualizar nosso Aviso de Privacidade quantas vezes forem necessárias, a fim de fornecer a você informações mais seguras e melhorar cada vez mais a sua experiência.</p>
<p dir="ltr" style="text-align: center; line-height: 1.5;"><strong>&nbsp;</strong><br></p>
<ol start=${hasBanner ? '8' : '7'} style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>LEGISLAÇÃO APLICÁVEL:</strong><br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;">Este documento é regido e deve ser interpretado conforme as leis e atos normativos que compõem o ordenamento jurídico brasileiro. Fica eleito o Foro da Comarca de ${
    !!address ? address.city : '[COLOQUE A CIDADE]'
  }, ${
    !!address ? address.state : '[COLOQUE UM ESTADO]'
  }, como o competente para dirimir eventuais questões oriundas do presente documento, com expressa renúncia a qualquer outro.</p>
<p dir="ltr" style="text-align: center; line-height: 1.5;"><strong>&nbsp;</strong><br></p>
<ol start=${hasBanner ? '9' : '8'} style="line-height: 1.5;">
  <li dir="ltr" aria-level="1" style="line-height: 1.5;">
    <p dir="ltr" role="presentation"><strong>CONTATO DO ENCARREGADO:</strong><br></p>
  </li>
</ol>
<p dir="ltr" style="line-height: 1.5;"> ${dpoDataCompany(
    company,
  )}, foi nomeado como Encarregado de Dados da Teste Da DPO e, assim, está apto a exercer todas as funções e atribuições do cargo previstas na LGPD.&nbsp;</p>
<p dir="ltr" style="line-height: 1.5;"><br></p>
${
  company?.dpoOrganization?.name
    ? `<p dir="ltr" style="line-height: 1.5;">Responsável: ${company?.dpoOrganization?.name}</p>`
    : ''
}
${
  company?.substitute
    ? `<p dir="ltr" style="line-height: 1.5;">Substituto: ${company?.substitute}</p>`
    : ''
}
<p dir="ltr" style="line-height: 1.5;">Contato: <a href="https://www.privacidade.com.br/portal-de-privacidade?token=${
    company?.token
  }">Privacidade&amp;Você</a></p>`
}

export default defaultPrivacy
