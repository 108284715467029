import { LogLevel } from '@azure/msal-browser'

import { getApplicationUrl, getMicrosoftClientId } from 'service/env'

const isProd = process.env.REACT_APP_ENV === 'production'

export const authConfig = {
  auth: {
    clientId: getMicrosoftClientId(),
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: `${getApplicationUrl()}/login/microsoft`,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: getApplicationUrl(),
    mainWindowRedirectUri: getApplicationUrl(),
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        if (isProd) {
          return
        } else {
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.info(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
            default:
              return
          }
        }
      },
    },
  },
}

export default authConfig
