import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  buttonLogin: {
    border: '1px solid #dadce0',
    width: '40px',
    height: '40px',
  },
}))

export default styles
