import React, { useEffect, useState } from 'react'
import { isEmpty, map } from 'lodash'

import { Box, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { BaseDialog, Label } from 'components'

import MultiSelect from '@kenshooui/react-multi-select'

import useCompanyFragility from 'hooks/useCompanyFragility'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'

const QuestionControlsDialog = ({
  open,
  setOpen,
  loadCategories,
  categories,
  defaultSelected,
}) => {
  const classes = useStyles()

  const {
    companyFragility,
    setMeasuresSelected,
    questionControls,
    setQuestionControls,
  } = useCompanyFragility()
  const snackbar = useSnackbar()

  const [selectedCategories, setSelectedCategories] = useState([])
  const [selection, setSelection] = useState(defaultSelected)
  const [questionControlOptions, setQuestionControlOptions] =
    useState(defaultSelected)

  const multiSelectSearch = (value) => (item) => {
    return (
      String(item.label).toLowerCase().includes(value.toLowerCase()) ||
      String(item.group).toLowerCase().includes(value.toLowerCase())
    )
  }

  const changeCategories = (_, value) => {
    setSelectedCategories(value)
  }

  useEffect(() => {
    if (open) {
      loadCategories()
    }
    //eslint-disable-next-line
  }, [open])

  const loadquestionControlOptions = async (categoryIds) => {
    if (!isEmpty(selectedCategories)) {
      const response = await service.dponet.questionControls.list({
        questionnaireKind: categoryIds,
        perPage: 9999,
        status: [
          constants.questionControl.ADOPTED_STATUS,
          constants.questionControl.RISK_ASSUMED_STATUS,
          constants.questionControl.TREATED_STATUS,
          constants.questionControl.PENDING_STATUS,
          constants.questionControl.IN_PROGRESS_STATUS,
          constants.questionControl.OVERDUE_STATUS,
          constants.questionControl.WAITING_ANSWER_STATUS,
        ],
      })
      return setQuestionControlOptions(
        helpers.questionControls.dataMultiOptions(
          response?.data?.questionControls,
        ),
      )
    }
    setQuestionControlOptions(selection)
  }

  useEffect(() => {
    loadquestionControlOptions(map(selectedCategories, 'id'))
    //eslint-disable-next-line
  }, [selectedCategories])

  const handleSave = async () => {
    try {
      if (!!companyFragility?.id) {
        const data = helpers.questionControls.manageOptions(
          questionControls,
          selection,
        )

        const response =
          await service.dponet.companyFragilities.manageQuestionControl({
            companyFragilityId: companyFragility.id,
            ...data,
          })

        setQuestionControls(response?.data?.companyFragilityMeasures ?? [])
      }

      snackbar.open({
        variant: 'success',
        message: 'Medidas se segurança atualizadas com sucesso!',
      })

      setMeasuresSelected(selection)
      setOpen(false)
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
  }

  return (
    <BaseDialog
      actionButton={handleSave}
      open={open}
      setOpen={setOpen}
      title={companyFragility?.id ? 'Editar medidas' : 'Adicionar medida'}
      closeButtonText="Voltar"
      actionButtonText="Salvar"
      dialogSize="lg"
      fullWidth
    >
      <Grid container spacing={2}>
        <Label
          titleClassName={classes.labelTitle}
          titleVariant="subtitle1"
          title="Selecione a categoria"
          item
          xs={12}
        >
          <Autocomplete
            getOptionLabel={(value) => value.name}
            options={categories}
            onChange={changeCategories}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            renderOption={(option) => (
              <Box className={classes.categoriesText}>{option.name}</Box>
            )}
            multiple
          />
        </Label>
        <Label
          titleClassName={classes.labelTitle}
          titleVariant="subtitle1"
          title="Medidas de segurança"
          item
          xs={12}
        >
          <MultiSelect
            messages={helpers.dataCollected.messagesMultiSelect()}
            items={questionControlOptions}
            withGrouping
            selectedItems={defaultSelected}
            onChange={(selected) => {
              setSelection(selected)

              return selected
            }}
            filterFunction={multiSelectSearch}
            wrapperClassName={classes.multiSelect}
          />
        </Label>
      </Grid>
    </BaseDialog>
  )
}

export default QuestionControlsDialog
