import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { sortBy, isEmpty } from 'lodash'

import { HelpCircle as HelpCircleIcon } from 'react-feather'
import {
  Box,
  Card,
  Container,
  Grid,
  TablePagination,
  useMediaQuery,
  Tabs,
  Tab,
  Button,
  Tooltip,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTheme } from '@material-ui/styles'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import {
  Page,
  Filters,
  FilterButton,
  DriveTour,
  Permitted,
  ContentHeader,
} from 'components'
import { QuestionTable } from './components'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'
import { routes } from 'Routes'

const QuestionControlsMain = () => {
  const [openTour, setOpenTour] = useState(false)
  const [categories, setCategories] = useState([])
  const history = useHistory()

  const filter = useFilter()
  const classes = useStyles()
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [currentTab, setCurrentTab] = useState(
    filter?.filters?.questionnaireKind || constants.questionControl.KIND.ALL,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.questionControls.list,
    {
      notStatus: constants.questionControl.INACTIVE_STATUS,
      page,
      perPage,
      ...filter.filters,
    },
    [page, perPage, filter.filters],
  )

  const questionControls = response?.data?.questionControls

  const handleChangeTabs = (_, value) => {
    setCurrentTab(value)

    if (value === constants.questionControl.KIND.ALL) {
      filter.setFilters({})
    } else {
      filter.setFilters({ questionnaireKind: value })
    }
  }

  const validationSteps = () => {
    if (questionControls === undefined) return
    if (!isLoading) {
      const steps = [
        ...constants.questionControl.QUESTION_CONTROL_DRIVER_STEPS_OBJECT,
      ]
      const statusSet = new Set()

      questionControls.forEach((question) => {
        const status = question?.status

        if (status && !statusSet.has(status)) {
          const stepObject = helpers.questionControls.statusDriverSteps(status)
          if (stepObject) {
            steps.push(stepObject)
            statusSet.add(status)
          }
        }
      })

      const sortedSteps = sortBy(steps, 'element')

      return sortedSteps
    }
    return []
  }

  useEffect(() => {
    const loadCategories = async () => {
      if (isEmpty(categories)) {
        const response = await service.dponet.questionControls.listCustomKinds()

        setCategories(response?.data?.questionControls)
      }
    }
    loadCategories()

    //eslint-disable-next-line
  }, [])

  const handleOpenTour = () => {
    const where = 'Medidas de Segurança'
    const title = 'Tour'

    const params = {
      action: 'tour-medidas-de-segurança-diagnostico',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const handleNew = () => history.push(routes.questionControls.new)

  return (
    <Page title="Todos as medidas de segurança">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Medidas de segurança">
          <Tooltip title="Iniciar o tour guiado">
            <Button
              id={constants.questionControl.QUESTION_CONTROL_DRIVER_STEP_1}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
            >
              Tutorial
            </Button>
          </Tooltip>
          <Permitted tag={constants.permissions.DIAGNOSTICS.UPDATE}>
            <Button
              id={constants.questionControl.QUESTION_CONTROL_DRIVER_STEP_2}
              variant="contained"
              color="primary"
              onClick={handleNew}
              fullWidth
            >
              Nova medida
            </Button>
          </Permitted>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} fullWidth />
        </ContentHeader>
        <Box my={4}>
          <Box mb={4}>
            <Tabs
              scrollButtons="auto"
              onChange={handleChangeTabs}
              textColor="primary"
              indicatorColor="primary"
              value={currentTab || constants.questionControl.KIND.ALL}
              className={classes.borderBottom}
              variant={'scrollable'}
            >
              <Tab
                key={constants.questionControl.KIND.ALL}
                value={constants.questionControl.KIND.ALL}
                label={<Box>Todos</Box>}
                wrapped
              />
              {categories.map((categorie) => (
                <Tab
                  key={categorie.id}
                  value={categorie.id}
                  label={
                    <Box className={classes.categoriesText}>
                      {categorie.name}
                    </Box>
                  }
                  wrapped
                />
              ))}
            </Tabs>
          </Box>
          <Card>
            <Box display="flex" width="100%">
              <Grid container>
                <QuestionTable
                  questionControls={questionControls}
                  refresh={refresh}
                />
              </Grid>
            </Box>

            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading && response ? (
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
          </Card>
        </Box>
        <Filters
          filter={filter}
          defaultOrder={constants.orderFilter.OLDER_CREATED}
        >
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            textfieldinput="true"
            label="Medida de segurança"
            name="title"
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            {constants.questionControl.STATUSES.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
          <input
            datepickerinput="true"
            label="Prazo maior que"
            name="deadlineDateGt"
          />
          <input
            datepickerinput="true"
            label="Prazo menor que"
            name="deadlineDateLt"
          />
        </Filters>
      </Container>

      <DriveTour
        stepsMatrix={validationSteps()}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default QuestionControlsMain
