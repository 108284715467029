import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  HelpCircle as HelpCircleIcon,
  Search as SearchIcon,
} from 'react-feather'
import { Box, Button, Paper, Tooltip } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  DriveTour,
  LoadingFeedback,
  Page,
  PaperHeader,
} from 'components'
import { MainBaseStructure } from '../components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import useStyles from './styles'

const InformationSecurityMain = () => {
  const classes = useStyles()
  const history = useHistory()

  const [openTour, setOpenTour] = useState(false)

  const { mixpanel } = helpers
  const { INFORMATION_SECURITY_ID, ACTIVE_STATUS } = constants.technicalFaqs

  const { response, isLoading } = useFetch(
    service.dponet.technicalFaqs.getSubcategory,
    {
      categoryId: INFORMATION_SECURITY_ID,
      minimal: true,
      status: ACTIVE_STATUS,
    },
  )

  const faqSubcategories = response?.data?.knowledgeBaseSubcategories

  const handleSearchAllFaqs = () => {
    const eventName = 'apoio_tecnico-si'
    const actionName = 'buscar_todas_as_faqs-si'

    const eventData = {
      action: actionName,
    }

    helpers.mixpanel.track(eventName, actionName, eventData)
    history.push(routes.technicalFaqs.informationSecurityShow)
  }

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Apoio Tecnico SI', 'Tour', {
      action: 'tour-seguranca-da-informacao-central-de-privacidade',
    })
  }

  return (
    <Page title="Apoio Técnico">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader title="Apoio Técnico">
          <Tooltip title="Iniciar o tour guiado">
            <Button
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
              id={constants.technicalFaqs.TECHNICAL_FAQS_SI_MAIN_DRIVER_STEP_1}
            >
              Tutorial
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            className={classes.searchButton}
            onClick={handleSearchAllFaqs}
          >
            <SearchIcon className={classes.searchIcon} />
            Buscar todas as faqs
          </Button>
        </ContentHeader>
        <Paper>
          <PaperHeader title="Segurança da Informação" />
          {!isLoading && (
            <MainBaseStructure
              subcategories={faqSubcategories}
              categoryId={INFORMATION_SECURITY_ID}
            />
          )}
        </Paper>
      </Container>
      <DriveTour
        stepsMatrix={
          constants.technicalFaqs.TECHNICAL_FAQS_SI_DRIVER_STEPS_OBJECT
        }
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default InformationSecurityMain
