import React from 'react'
import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useStyles from './styles'
import HistoryBreadcumbs from './components/HistoryBreadcumbs/HistoryBreadcumbs'

const ContentHeader = ({
  title,
  lightColor = false,
  children,
  smFull = false,
  xsFull = true,
  breakLine = true,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Box
      pt={title && 3}
      mb={title && 4}
      className={clsx(classes.root, {
        [classes.rootBreakpoints]: breakLine,
      })}
    >
      <Box
        className={clsx(classes.titleContainer, {
          [classes.titleContainerBreakpoints]: breakLine,
        })}
      >
        <HistoryBreadcumbs lightColor={lightColor} />
        <Typography
          className={clsx(classes.h3, { [classes.textLighten]: lightColor })}
          variant="h3"
          color="textPrimary"
        >
          {title}
        </Typography>
      </Box>
      {!!children && (
        <Box
          className={clsx(classes.childrenContainer, {
            [classes.childrenContainerBreakpoints]: breakLine,
          })}
          {...rest}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.string,
  lightColor: PropTypes.bool,
  children: PropTypes.node,
  smFull: PropTypes.bool,
  xsFull: PropTypes.bool,
}

export default ContentHeader
