const isSelfSelected = (agents) => {
  if (!agents) return false

  const types = agents?.map((agent) => {
    if (agent?.companySelf) {
      return true
    }
    return false
  })

  return types.includes(true)
}

export default isSelfSelected
