import React, { useState, useEffect } from 'react'
import { HelpCircle as HelpCircleIcon, Edit3 as EditIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Container,
  Paper,
  Box,
  CircularProgress,
  Button,
  Tooltip,
  Tabs,
  Tab,
} from '@material-ui/core'

import { FormEdit } from './components/Edit/Form'
import {
  Page,
  ContentHeader,
  MenuButton,
  ConfirmationRiskDialog,
  DriveTour,
} from 'components'
import { PaperHeader } from './components/Edit'
import {
  DialogConfirm,
  DeleteDataProcessDialog,
  DialogRefuseSuggested,
  LegitimateInterest,
} from './components'

import DataProcessProvider from 'providers/DataProcessProvider'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcessOptions from 'hooks/useDataProcessOptions'

import DataProcessTemplateQuestionProvider from 'providers/DataProcessTemplateQuestionProvider'

import helpers from 'helpers'
import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import useStyles from './styles'

const EditPage = ({ match }) => {
  const history = useHistory()
  const { dataProcessId } = match.params

  const classes = useStyles()
  const { company } = useAuth()

  const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
  const [confirmRiskOpen, setConfirmRiskOpen] = useState(false)
  const [adoptedFragilityId, setAdoptedFragilityId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState()
  const snackbar = useSnackbar()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [hasOrigin, setHasOrigin] = useState(false)
  const [defaultFragilityId, setDefaultFragilityId] = useState(null)
  const [openTour, setOpenTour] = useState(false)
  const [refuseDialog, setRefuseDialog] = useState({
    open: false,
    message:
      'Deseja realmente excluir o processo? Todas as alterações feitas no processo serão perdidas',
    type: 'discard',
  })
  const [value, setValue] = useState(0)

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }

  const openRefuse = (props) => {
    setRefuseDialog({ ...refuseDialog, ...props })
  }

  const dataProcessOptions = useDataProcessOptions()

  if (!dataProcessOptions.data) {
    dataProcessOptions.loadData(company.id || '')
  }

  const { PENDING_STATUS, DISAPPROVED_STATUS, PENDING_LIA, DISAPPROVED_LIA } =
    constants.dataProcess

  const loadDataProcess = async () => {
    setLoading(true)
    let dataProcess = await service.dponet.dataProcesses.get({
      dataProcessId,
    })

    if (
      ![
        PENDING_STATUS,
        DISAPPROVED_STATUS,
        PENDING_LIA,
        DISAPPROVED_LIA,
      ].includes(dataProcess.data.dataProcess.statusId)
    ) {
      service.history.push(`/data-processes/show/${dataProcessId}`)
    }

    setResponse(dataProcess)
    setHasOrigin(dataProcess?.data?.dataProcess?.originDataProcessId !== null)
    setLoading(false)
  }

  useEffect(() => {
    loadDataProcess()
    //eslint-disable-next-line
  }, [dataProcessId, company])

  useEffect(() => {
    const DataProcessStatus = response?.data?.dataProcess?.statusId

    if (
      DataProcessStatus === DISAPPROVED_LIA ||
      DataProcessStatus === PENDING_LIA
    ) {
      setValue(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const handleSubmit = () => {
    if (response?.data?.dataProcess?.acceptProcessFragility) {
      setConfirmRiskOpen(true)
    } else {
      setModalConfirmOpen(true)
    }
  }

  const sendToReview = async () => {
    setLoading(true)
    setModalConfirmOpen(false)
    setConfirmRiskOpen(false)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId,
        statusId: constants.dataProcess.WAITING_REVIEW_STATUS,
        adoptedFragilityId: adoptedFragilityId || defaultFragilityId,
      })
      history.push(
        reverse(routes.dataProcess.view, { dataProcessId: dataProcessId }),
      )
      snackbar.open({
        message: 'Processo enviado para a revisão!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error) ||
          'Ocorreu um erro, tente novamente',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const getBackToList = () => {
    dataProcessOptions.setReload(true)
  }

  const handleOpenTour = () => {
    const where = 'Processos'
    const title = 'Tour'

    const params = {
      action: 'tour-editar-processos',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const infoTabs = [
    { label: 'PROCESSO COMPLETO', value: 0 },
    { label: 'LEGÍTIMO INTERESSE (LIA)', value: 1 },
  ]

  const data_process_status_id = response?.data?.dataProcess?.statusId

  const statusPendingLia =
    data_process_status_id === constants.dataProcess.PENDING_LIA

  const statusDisapprovedLia =
    data_process_status_id === constants.dataProcess.DISAPPROVED_LIA

  const showTabLia = statusPendingLia || statusDisapprovedLia

  const enableButton =
    helpers.dataProcess.statusesCheck.isPending(response?.data?.dataProcess) ||
    helpers.dataProcess.statusesCheck.disapproved(response?.data?.dataProcess)

  return (
    <>
      <Page className={classes.root} title="Editar processo">
        <Container maxWidth={false} className={classes.container}>
          <Box py={2} mt={1}>
            <ContentHeader title="Processo">
              {enableButton && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditIcon size={20} />}
                  onClick={handleSubmit}
                  id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_9}
                >
                  Enviar para revisão
                </Button>
              )}
              <Box className={classes.contentHeaderBox}>
                <Tooltip title="Iniciar o tour guiado">
                  <Button
                    id={constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEP_1}
                    variant="contained"
                    color="primary"
                    startIcon={<HelpCircleIcon size={20} />}
                    onClick={handleOpenTour}
                    fullWidth
                  >
                    Tutorial
                  </Button>
                </Tooltip>
                {enableButton && (
                  <MenuButton>
                    <Button
                      onClick={() => {
                        if (hasOrigin) {
                          return openRefuse({ open: true })
                        }
                        setOpenDeleteDialog(true)
                      }}
                      fullWidth
                    >
                      Excluir
                    </Button>
                  </MenuButton>
                )}
              </Box>
            </ContentHeader>
            {showTabLia && (
              <>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  component={Box}
                  mb={3}
                >
                  {infoTabs.map((infoTab) => (
                    <Tab
                      key={infoTab.value}
                      value={infoTab.value}
                      label={infoTab.label}
                      onClick={(e) => handleChange(e, infoTab.value)}
                    />
                  ))}
                </Tabs>
              </>
            )}

            {(value === infoTabs[0].value || !showTabLia) &&
              (loading ? (
                <Box
                  id="loadBox"
                  display="flex"
                  width="100%"
                  minHeight="700px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Paper className={classes.paperMargin}>
                  <PaperHeader
                    data={response?.data?.dataProcess}
                    isLoading={loading}
                  />
                  <DataProcessTemplateQuestionProvider
                    dataProcessTemplate={dataProcessOptions.dataProcessTemplate}
                    step="general_information"
                    dataProcessTemplateAnswers={
                      response?.data?.dataProcess?.dataProcessTemplateAnswers ||
                      []
                    }
                  >
                    <FormEdit
                      dataProcess={response?.data?.dataProcess}
                      loading={loading}
                      refresh={loadDataProcess}
                    />
                  </DataProcessTemplateQuestionProvider>
                </Paper>
              ))}
            {value === infoTabs[1].value && !loading && (
              <DataProcessProvider>
                <LegitimateInterest
                  dataProcess={response?.data?.dataProcess}
                  statusPendingLia={statusPendingLia}
                />
              </DataProcessProvider>
            )}
            {modalConfirmOpen && (
              <DialogConfirm
                title="Enviar processo para a revisão"
                description="Deseja enviar esse processo para revisão?"
                confirmText="Enviar para revisão"
                open={modalConfirmOpen}
                setOpen={setModalConfirmOpen}
                handleConfirm={sendToReview}
              />
            )}
            {confirmRiskOpen && (
              <ConfirmationRiskDialog
                open={confirmRiskOpen}
                setOpen={setConfirmRiskOpen}
                handleConfirm={sendToReview}
                dataProcessId={dataProcessId}
                setAdoptedFragilityId={setAdoptedFragilityId}
                setDefaultFragilityId={setDefaultFragilityId}
              />
            )}
            <DeleteDataProcessDialog
              dataProcessId={dataProcessId}
              open={openDeleteDialog}
              setOpen={setOpenDeleteDialog}
              action={getBackToList}
              redirectTo={routes.dataProcess.all}
            />
            <DialogRefuseSuggested
              refuseDialog={refuseDialog}
              setRefuseDialog={setRefuseDialog}
              dataProcess={response?.data?.dataProcess}
              setIsLoading={setLoading}
              redirectTo={routes.dataProcess.all}
            />
            <DriveTour
              stepsMatrix={
                constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEPS_OBJECT
              }
              customStyle={classes.customPopover}
              open={openTour}
              setOpen={setOpenTour}
            />
          </Box>
        </Container>
      </Page>
    </>
  )
}

export default EditPage
