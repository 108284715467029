const CONTROLLER_TYPE = 'CONTROLLER'
const OPERATOR_TYPE = 'OPERATOR'
const SUBOPERATOR_TYPE = 'SUBOPERATOR'
const CO_CONTROLLER_TYPE = 'CO_CONTROLLER'

const CONTROLLER_LABEL = 'Controlador'
const OPERATOR_LABEL = 'Operador'
const SUBOPERATOR_LABEL = 'Suboperador'
const CO_CONTROLLER_LABEL = 'Co-controlador'

const OPTIONS_TYPES = [
  { id: CONTROLLER_TYPE, name: CONTROLLER_LABEL },
  { id: OPERATOR_TYPE, name: OPERATOR_LABEL },
  { id: SUBOPERATOR_TYPE, name: SUBOPERATOR_LABEL },
  { id: CO_CONTROLLER_TYPE, name: CO_CONTROLLER_LABEL },
]

const TRANSLATED_TYPE = {
  CONTROLLER: CONTROLLER_LABEL,
  OPERATOR: OPERATOR_LABEL,
  SUBOPERATOR: SUBOPERATOR_LABEL,
  CO_CONTROLLER: CO_CONTROLLER_LABEL,
}

const DESCRIPTIONS = [
  'Controlador: É a pessoa ou empresa que decide como os dados pessoais serão' +
    'tratados. Ele é responsável por definir a finalidade e as bases legais do processamento dos dados.',
  'Operador: É a pessoa ou empresa contratada pelo controlador para processar os dados de acordo com suas instruções.' +
    'Pode ser uma empresa de processamento de dados ou provedor de serviços em nuvem.',
  'Sub-operador: É uma pessoa ou empresa indicada pelo operador para atuar como intermediário entre o ' +
    'controlador, operador e os titulares dos dados. Ele recebe as demandas dos titulares e as encaminha ao controlador ' +
    'ou operador, garantindo o exercício dos direitos dos titulares.',
]

const REQUIREDS =
  'É necessário ter pelo menos um controlador de dados e a empresa precisa ser incluída como agente.'

export default {
  CO_CONTROLLER_LABEL,
  CO_CONTROLLER_TYPE,
  CONTROLLER_LABEL,
  CONTROLLER_TYPE,
  DESCRIPTIONS,
  OPERATOR_LABEL,
  OPERATOR_TYPE,
  OPTIONS_TYPES,
  REQUIREDS,
  SUBOPERATOR_LABEL,
  SUBOPERATOR_TYPE,
  TRANSLATED_TYPE,
}
