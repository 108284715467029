import * as yup from 'yup'

const schema = yup.object({
  dataProcess: yup.object().shape({
    id: yup.number().required('O processo é obrigatório'),
    name: yup.string().required('O processo é obrigatório'),
  }),
})

export default schema
