import React from 'react'
import PropTypes from 'prop-types'

import { Info as InfoIcon, Check as CheckIcon } from 'react-feather'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import theme from 'theme'

const SourceCodeDialog = ({ open, setOpen, actionButton }) => {
  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Como instalar no HTML (Via código fonte)"
      fullWidth
      closeIconButton
      withoutBottomDivider
      dialogSize="md"
      actionButton={actionButton}
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <Box
          p={2}
          bgcolor={theme.palette.custom.light}
          borderRadius={1}
          display="flex"
          alignItems="center"
          gridGap={theme.spacing(2)}
        >
          <Box>
            <InfoIcon color={theme.palette.primary.main} />
          </Box>
          <Typography color="primary" mt={1}>
            Para inserir o código do banner, é necessário ter acesso ao
            código-fonte do seu site.
          </Typography>
        </Box>
        <Box
          borderRadius={1}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2.5)}
        >
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            <CheckIcon />
            <Typography>
              Clique em <strong>VER CÓDIGO</strong>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            <CheckIcon />
            <Typography>
              Selecione todo o código ou utilize o botão de copiar
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            <CheckIcon />
            <Typography>
              Cole no final do corpo/body do site sem mais nenhuma tag para
              interferir
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            <CheckIcon />
            <Typography>Salve as alterações</Typography>
          </Box>
        </Box>
      </Box>
    </BaseDialog>
  )
}

SourceCodeDialog.propTypes = {
  actionButton: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default SourceCodeDialog
