/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'

import { LoadingFeedback } from 'components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'

const MicrosoftLogin = () => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()
  const isAuthenticated = useIsAuthenticated()
  const { instance, inProgress, accounts } = useMsal()
  const snackbar = useSnackbar()

  const requestSocialProvider = async (accessToken) => {
    try {
      const responseSocialProvider =
        await service.dponet.auth.loginSocialProvider({
          socialProvider: 'microsoft',
          credential: {
            token: accessToken,
          },
        })

      if (responseSocialProvider.status === 202) {
        const accessToken = responseSocialProvider.data?.accessToken
        const user = responseSocialProvider.data?.user

        history.push({
          pathname: reverse(routes.twoFactorAuthentication, { accessToken }),
          state: {
            email: user?.email,
          },
        })
      } else {
        await auth.loadDataWithAnimation()
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
      instance.logoutRedirect({
        onRedirectNavigate: () => {
          return false
        },
      })
      history.push('/')
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      snackbar.open({
        message:
          'Aconteceu algum erro inesperado ao realizar o login com a Microsoft. Tente novamente, se o erro persistir, entre em contato com nosso suporte.',
        variant: 'error',
      })
      instance.logoutRedirect({
        onRedirectNavigate: () => {
          return false
        },
      })
      history.push('/')
    }, 15000)

    if (isAuthenticated && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: ['User.Read'],
        })
        .then((response) => {
          requestSocialProvider(response.accessToken)
        })
    }
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line
  }, [inProgress, isAuthenticated, accounts, instance])

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Box className={classes.boxLoginMicrosoft}>
        <Typography
          variant="h3"
          color="primary"
          className={classes.textLoginMicrosoft}
        >
          Realizando login com a Microsoft, aguarde...
        </Typography>
      </Box>
      <LoadingFeedback open={true} />
    </Container>
  )
}

export default MicrosoftLogin
