import {
  CheckCircle as CheckCircleIcon,
  AlertTriangle as AlertFailedIcon,
  Loader as LoaderIcon,
} from 'react-feather'

import theme from 'theme'

// STATUS DE BUILD LOG
const COMPLETED_BUILD_STATUS_ID = 'succeded'
const INITIATED_BUILD_STATUS_ID = 'initiated'
const FAILED_BUILD_STATUS_ID = 'failed'

const BUILD_STATUS_TRANSLATION = {
  [COMPLETED_BUILD_STATUS_ID]: 'Completo',
  [FAILED_BUILD_STATUS_ID]: 'Falha',
  [INITIATED_BUILD_STATUS_ID]: 'Gerando',
}

const BUILD_STATUS_COLOR = {
  [COMPLETED_BUILD_STATUS_ID]: theme.palette.tertiary.main,
  [FAILED_BUILD_STATUS_ID]: theme.palette.error.main,
  [INITIATED_BUILD_STATUS_ID]: theme.palette.primary.main,
}

const BUILD_STATUS_ICON = {
  [COMPLETED_BUILD_STATUS_ID]: CheckCircleIcon,
  [FAILED_BUILD_STATUS_ID]: AlertFailedIcon,
  [INITIATED_BUILD_STATUS_ID]: LoaderIcon,
}

const banners = {
  COMPLETED_BUILD_STATUS_ID,
  FAILED_BUILD_STATUS_ID,
  INITIATED_BUILD_STATUS_ID,
  BUILD_STATUS_COLOR,
  BUILD_STATUS_ICON,
  BUILD_STATUS_TRANSLATION,
}

export default banners
