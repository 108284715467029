import React, { useState } from 'react'

import { Box, Typography, Button } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { PlusCircle as PlusCircleIcon } from 'react-feather'

import useSnackbar from 'hooks/useSnackbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { AddDialog } from './components'
import { LoadingFeedback, Permitted } from 'components'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import { Alert } from '@material-ui/lab'

const DataProcessIncidentTable = ({
  dataProcesses,
  isLoading,
  incidentId,
  onEvent,
}) => {
  const [openAddProcessDialog, setOpenAddProcessDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleOpenAddProcessDialog = () => setOpenAddProcessDialog(true)

  const handleDelete = async (dataProcessId) => {
    setLoading(true)
    try {
      await service.dponet.incidents.unlinkProcess({
        incidentId,
        dataProcessId,
      })

      snackbar.open({
        message: 'Processo desvinculado com sucesso',
        variant: 'success',
      })

      onEvent()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao desvincular processo',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback isLoading={isLoading || loading} />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Processamento de dados vinculados</Typography>
        <Permitted tag={constants.permissions.INCIDENTS.CREATE}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<PlusCircleIcon />}
            onClick={handleOpenAddProcessDialog}
          >
            Adicionar processo
          </Button>
        </Permitted>
      </Box>
      <Box my={2}>
        <Alert severity="info">
          <Typography variant="body2">
            É possível vincular apenas processos de dados associados a um
            departamento ao qual você também está vinculado.
          </Typography>
        </Alert>
      </Box>
      {!isLoading && (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum processo encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="40%">Processamento de dados</TableCell>
                  <TableCell width="40%">Departamento</TableCell>
                  <TableCell width="10%">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataProcesses?.map((dataProcess) => (
                  <TableRow key={dataProcess?.id}>
                    <TableCell>{dataProcess?.id}</TableCell>
                    <TableCell>{dataProcess?.name}</TableCell>
                    <TableCell>{dataProcess?.department?.name}</TableCell>
                    <TableCell>
                      <Permitted tag={constants.permissions.INCIDENTS.CREATE}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => handleDelete(dataProcess?.id)}
                        >
                          Excluir
                        </Button>
                      </Permitted>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
      {openAddProcessDialog && (
        <AddDialog
          open={openAddProcessDialog}
          setOpen={setOpenAddProcessDialog}
          incidentId={incidentId}
          onEvent={onEvent}
          dataProcessesId={
            dataProcesses?.map((dataProcess) => dataProcess?.id) || []
          }
        />
      )}
    </>
  )
}

export default DataProcessIncidentTable
