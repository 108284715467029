import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 10,
  },
  welcomeText: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  boxButtonsSocial: {
    gap: '20px',
  },
  boxLoginMicrosoft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  textLoginMicrosoft: {
    textAlign: 'center',
    fontWeight: 600,
  },
}))

export default styles
