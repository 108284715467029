const defineErrorMessage = (error) => {
  const response = error?.response
  const status = response?.status
  const errorMessage = response?.data?.error?.message

  if ((status === 401 || status === 422) && errorMessage) return errorMessage

  return 'Ocorreu algum erro! Tente novamente!'
}

export default defineErrorMessage
