import React, { memo, useState } from 'react'
import { Box, Button, Container, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Form, ResendEmail } from './components'

import useStyles from './styles'
import { routes } from 'Routes'

const TwoFactorAuthenticationMain = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const accessToken = match.params.accessToken

  const handleBack = () => {
    if (isAuthenticated) {
      instance.logoutRedirect({
        onRedirectNavigate: () => {
          return false
        },
      })
    }
    history.push(routes.root)
  }
  return (
    <Box className={classes.root}>
      <Container maxWidth="xs">
        <Typography variant="h4" color="textPrimary">
          Enviamos um código de verificação no seu e-mail{' '}
          <strong className={classes.highlightedText}>
            {location.state?.email}
          </strong>
        </Typography>

        <Form accessToken={accessToken} setLoading={setLoading} />
        <ResendEmail accessToken={accessToken} />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={4}
        >
          <Button
            type="submit"
            variant="contained"
            className={classes.buttonPrimary}
            form="2fa-form"
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Acessar'}
          </Button>
          <Button onClick={handleBack} variant="text" color="primary">
            Voltar
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

TwoFactorAuthenticationMain.propTypes = {
  match: PropTypes.object.isRequired,
}

export default memo(TwoFactorAuthenticationMain)
