const mountOccurrenceLocations = (occurrenceLocations) => {
  if (!occurrenceLocations) return

  const names = Object.keys(occurrenceLocations)
  const values = Object.values(occurrenceLocations)

  return { names, values }
}

export default mountOccurrenceLocations
