import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dividerContainer: {
    paddingBlock: 5,
    paddingInline: 10,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textAlign: 'justify',
  },
  customPopover: {
    maxWidth: 500,
  },
}))

export default styles
