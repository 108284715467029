import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  HelpCircle as HelpCircleIcon,
  PlusCircle as PlusCircleIcon,
} from 'react-feather'
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TablePagination,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isEmpty } from 'lodash'

import {
  Page,
  ContentHeader,
  Permitted,
  Filters,
  FilterButton,
  ButtonAction,
  DriveTour,
} from 'components'
import UsersTable from './components/UsersTable'
import ImportUsersDialog from './components/ImportUsersDialog'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import usePermissions from 'hooks/usePermissions'

import * as service from 'service'
import { routes } from 'Routes'
import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'
import helpers from 'helpers'

const UsersMain = () => {
  const [openTour, setOpenTour] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [profiles, setProfiles] = useState([])

  const filter = useFilter()
  const history = useHistory()
  const classes = useStyles()
  const permissions = usePermissions()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const hasCreatePermission = permissions.permitted(
    constants.permissions.USERS.CREATE,
  )

  const hasEditPermission = permissions.permitted(
    constants.permissions.USERS.EDIT,
  )

  const hasDeletePermission = permissions.permitted(
    constants.permissions.USERS.SOFT_DELETE,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.usersCompanies.list,
    {
      listByCompany: true,
      listInactives: true,
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  const userCompanies = response?.data?.userCompanies

  const handleNew = () => {
    history.push(routes.user.new)
  }

  const handleOpenDialog = () => {
    helpers.mixpanel.track('Usuários', 'Importar Usuários', {
      action: 'importar_usuários-Usuários',
    })

    setOpenDialog(!openDialog)
  }

  const handleOpenTour = () => {
    const where = 'Usuários'
    const title = 'Tour'

    const params = {
      action: 'tour-usuários-empresas',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const filtersMatrixSteps = (elementId, permission) => {
    const requiredPermission =
      constants.user.USERS_DRIVER_STEPS_WITH_PERMISSIONS[elementId]

    if (
      (isEmpty(userCompanies) ||
        (!hasEditPermission && !hasDeletePermission)) &&
      elementId === '#' + constants.user.USER_MAIN_DRIVER_STEP_3
    )
      return false

    return requiredPermission ? permission(requiredPermission) : true
  }

  const filteredSteps = constants.user.USERS_DRIVER_STEPS_OBJECT.filter(
    (step) => {
      return filtersMatrixSteps(step.element, permissions.permitted)
    },
  )

  const disableTour =
    !hasCreatePermission &&
    ((!hasEditPermission && !hasDeletePermission) || isEmpty(userCompanies))

  useEffect(() => {
    const getProfiles = async () => {
      const response = await service.dponet.profiles.get({
        status: true,
        profileType: constants.profile.PROFILE_CLIENT_TYPE,
        perPage: 1000,
      })

      setProfiles(response?.data?.profiles)
    }

    getProfiles()
  }, [])

  return (
    <Page title="Todos os usuários">
      <Container maxWidth={false} className={classes.container}>
        <ImportUsersDialog
          profiles={profiles}
          open={openDialog}
          setOpen={setOpenDialog}
          refresh={refresh}
        />
        <ContentHeader title="Usuários" smFull>
          <Tooltip
            title={
              disableTour
                ? "Para iniciar este tour guiado, é necessário que você possua permissões para 'criar', 'editar' ou 'excluir' como algum usuário definido."
                : 'Iniciar o tour guiado'
            }
          >
            <Button
              id={constants.user.USER_MAIN_DRIVER_STEP_0}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              disabled={disableTour}
              fullWidth
            >
              Tutorial
            </Button>
          </Tooltip>
          <Permitted tag={constants.permissions.USERS.CREATE}>
            <ButtonAction
              id={constants.user.USER_MAIN_DRIVER_STEP_1}
              variant="contained"
              color="primary"
              action={handleOpenDialog}
              name="IMPORTAR USUÁRIOS"
              fullWidth
            />
            <ButtonAction
              id={constants.user.USER_MAIN_DRIVER_STEP_2}
              variant="outlined"
              color="primary"
              action={handleNew}
              name="NOVO USUÁRIO"
              startIcon={<PlusCircleIcon width="18" />}
              fullWidth
            />
          </Permitted>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} fullWidth />
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <UsersTable
                userCompanies={userCompanies}
                isLoading={isLoading}
                profiles={profiles}
                refresh={refresh}
              />
            </Grid>
          </Box>

          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters
          filter={filter}
          defaultOrder={constants.orderFilter.OLDER_CREATED}
        >
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Nome" name="userName" />
          <input textfieldinput="true" label="Email" name="userEmail" />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={constants.userCompanies.ACTIVE}>Ativo</option>
            <option value={constants.userCompanies.INACTIVE}>Inativo</option>
          </select>
          <select textfieldinput="true" label="Perfil" name="profileId">
            <option value=""></option>
            {profiles.map((profile) => (
              <option key={profile.id} value={profile.id}>
                {profile.name}
              </option>
            ))}
          </select>
        </Filters>
      </Container>

      <DriveTour
        stepsMatrix={filteredSteps}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default UsersMain
