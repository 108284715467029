import getSupplierByCompliance from './getSupplierByCompliance'
import mountOccurrenceLocations from './mountOccurrenceLocations'
import mountQuestionControlStatsRows from './mountQuestionControlStatsRows'
import questionControlStatsByStatus from './questionControlStatsByStatus'
import threatsByDepartment from './threatsByDepartment'
import trimChartLegend from './trimChartLegend'

const dashboard = {
  getSupplierByCompliance,
  mountOccurrenceLocations,
  mountQuestionControlStatsRows,
  questionControlStatsByStatus,
  threatsByDepartment,
  trimChartLegend,
}

export default dashboard
