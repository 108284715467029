import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { Box, Button, Chip, Link } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { ConfirmationDialog, MenuButton } from 'components'

import * as service from 'service'
import helpers from 'helpers'

import useStyles from './styles'
import useSnackbar from 'hooks/useSnackbar'
import { routes } from 'Routes'

const BannersTable = ({ banners, isLoading, refresh }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedBanner, setSelectedBanner] = useState('')
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const navigateToSettings = (id) => {
    history.push(
      reverse(routes.cookies.settings, {
        id: id,
      }),
    )
  }

  const handleOpenModal = (bannerId) => {
    setSelectedBanner(bannerId)
    setOpenModal(true)
  }

  const handleDeleteBanner = async () => {
    try {
      await service.cookies.banner.destroy(selectedBanner)

      snackbar.open({
        message: 'Banner deletado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setOpenModal(false)
      refresh()
    }
  }

  return (
    <>
      <Box width="100%">
        <PerfectScrollbar
          options={{ wheelPropagation: false, useBothWheelAxes: true }}
        >
          <Table
            size="small"
            emptyMessage="Nenhum banner cadastrado"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>URL</TableCell>
                <TableCell align="center">Acessos</TableCell>
                <TableCell align="center">Planos</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banners?.map((banner) => (
                <TableRow key={banner?.id}>
                  <TableCell>{banner?.id}</TableCell>
                  <TableCell>{banner?.name}</TableCell>
                  <TableCell>
                    <Link href={banner?.url}>{banner?.url}</Link>
                  </TableCell>
                  <TableCell align="center">
                    {banner?.requestsCount || '-'}
                  </TableCell>
                  <TableCell align="center">
                    {banner?.optOut || 'Leavening'}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={helpers.cookies.banner.chipStatusLabel(
                        banner?.status,
                      )}
                      style={{
                        backgroundColor: helpers.cookies.banner.chipStatusColor(
                          banner?.status,
                        ),
                        color: helpers.cookies.banner.chipFontStatusColor(
                          banner?.status,
                        ),
                      }}
                      size="small"
                      className={classes.chip}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <MenuButton>
                      <Button
                        color="secondary"
                        onClick={() => navigateToSettings(banner?.id)}
                        fullWidth
                        size="small"
                      >
                        Configurar
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => handleOpenModal(banner?.id)}
                        fullWidth
                        size="small"
                      >
                        Excluir
                      </Button>
                    </MenuButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
      <ConfirmationDialog
        open={openModal}
        setOpen={setOpenModal}
        title="Você tem certeza que deseja excluir este banner?"
        textButton="Excluir"
        actionAcceptButton={handleDeleteBanner}
        text="Todas as informações serão permanentemente excluídas, não será possível recuperar-las."
      />
    </>
  )
}

BannersTable.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
}

export default BannersTable
