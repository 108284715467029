import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { sortBy, isEmpty } from 'lodash'

import {
  Box,
  Card,
  Container,
  Grid,
  makeStyles,
  TablePagination,
  Tooltip,
  Button,
} from '@material-ui/core'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import useAuth from 'hooks/useAuth'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

import {
  Page,
  FilterButton,
  Filters,
  ContentHeader,
  DriveTour,
} from 'components'
import { OrdersTable } from './components'

import styles from './styles'

const useStyles = makeStyles(styles)

const Orders = () => {
  const { company } = useAuth()
  const filter = useFilter()
  const classes = useStyles()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(4)

  const [openTour, setOpenTour] = useState(false)

  const { response, isLoading, refresh } = useFetch(
    service.marketPlace.orders.get,
    {
      ...{ ...filter.filters },
      externalId: company?.id,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const orders = response?.data?.orders

  const validationSteps = () => {
    const steps = [constants.order.ORDER_DRIVER_STEP_OBJECT_1]
    if (!isLoading && !isEmpty(orders)) {
      const statusSet = new Set()

      orders.forEach((order) => {
        const status = order?.status

        if (status && !statusSet.has(status)) {
          const stepObject = constants.order.ORDER_DRIVER_SELECTED_STEPS[status]
          if (stepObject) {
            steps.push(stepObject)
            statusSet.add(status)
          }
        }
      })

      const sortedSteps = sortBy(steps, 'element')

      return sortedSteps
    }
    return steps
  }

  const handleOpenTour = () => {
    const where = 'Marketplace'
    const title = 'Tour'

    const params = {
      action: 'tour-meus-pedidos-marketplace',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Marketplace - Meus pedidos">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Marketplace - Meus Pedidos">
          <Tooltip title="Iniciar o tour guiado">
            <Button
              id={constants.order.ORDER_DRIVER_STEP_1}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
            >
              Tutorial
            </Button>
          </Tooltip>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <OrdersTable
                orders={orders}
                isLoading={isLoading}
                onEvent={refresh}
              />
            </Grid>
          </Box>
        </Card>
        <Box>
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[4, 12, 36, 100]}
            labelRowsPerPage="Por página"
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            datepickerinput="true"
            label="Data de criação maior ou igual a"
            name="createdAtGt"
          />
          <input
            datepickerinput="true"
            label="Data de criação menor ou igual a"
            name="createdAtLt"
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value="requested">Solicitado</option>
            <option value="actived">Ativo</option>
            <option value="canceled">Cancelado</option>
          </select>
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={validationSteps()}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default Orders
