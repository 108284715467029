import React from 'react'
import {
  Download as DownloadIcon,
  FileText as FileTextIcon,
} from 'react-feather'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'

import helpers from 'helpers'

import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'
import { MenuButton } from 'components'

const InvoiceTable = ({
  isLoading,
  paymentOrders,
  refresh,
  handleViewContract,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const handleRequest = async (paymentOrderId, method) => {
    try {
      const response = await service.dponet.paymentOrders[method]({
        paymentOrderId,
      })

      window.open(response?.data, '_blank')
    } catch (error) {
      if (method === 'billet') refresh()
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  const resendNotification = async (paymentOrderId) => {
    try {
      await service.dponet.financialPanel.notifyPaymentOrder(paymentOrderId)
      snackbar.open({
        message: 'A fatura foi enviada para os e-mails cadastrados.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  return (
    <PerfectScrollbar
      options={{
        useBothWheelAxes: true,
        suppressScrollX: false,
        swipeEasing: true,
      }}
    >
      <Table
        size="medium"
        emptyMessage="Nenhuma fatura encontrada"
        isLoading={isLoading}
        noWrap
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" width="5%">
              ID
            </TableCell>
            <TableCell align="left" width="10%">
              Método de pagamento
            </TableCell>
            <TableCell align="left" width="15%">
              Nº do contrato
            </TableCell>
            <TableCell align="center" width="15%">
              Data de vencimento
            </TableCell>
            <TableCell align="center" width="15%">
              Mês de referência
            </TableCell>
            <TableCell align="center" width="10%">
              Nota fiscal
            </TableCell>
            <TableCell align="center" width="10%">
              Fatura
            </TableCell>
            <TableCell align="center" width="15%">
              Status
            </TableCell>
            <TableCell align="center" width="5%">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentOrders?.map((order) => (
            <TableRow key={order?.id}>
              <TableCell align="center">{order?.id}</TableCell>
              <TableCell align="left">{order?.paymentMethod || '-'}</TableCell>
              <TableCell
                align="left"
                className={classes.linkCell}
                onClick={() => handleViewContract(order?.contractIdentifier)}
              >
                {order?.contractIdentifier}
              </TableCell>
              <TableCell align="center">
                {order?.expirationDate
                  ? moment(order?.expirationDate).format('DD/MM/YYYY')
                  : '-'}
              </TableCell>
              <TableCell align="center">
                {helpers.functions.capitalizeText(
                  moment(order?.orderReferenceDate).format('MMMM/YYYY'),
                )}
              </TableCell>
              <TableCell align="center">
                <Tooltip title={!order?.haveNfe ? 'NF-e não emitida' : ''}>
                  <Box display="block">
                    <IconButton
                      size="small"
                      disabled={!order?.haveNfe}
                      onClick={() => handleRequest(order?.id, 'nfe')}
                    >
                      <FileTextIcon />
                    </IconButton>
                    {order?.numberNfe && (
                      <Typography variant="body2">
                        {order?.numberNfe}
                      </Typography>
                    )}
                  </Box>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                {constants.settings.HIDE_BILLET_STATUSES.includes(
                  order?.status,
                ) ? (
                  '-'
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => handleRequest(order?.id, 'billet')}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell align="center">
                <Chip
                  size="small"
                  label={helpers.validates.settings.chipStatusLabel(
                    order?.status,
                  )}
                  style={{
                    backgroundColor:
                      helpers.validates.settings.chipStatusBgColor(
                        order?.status,
                      ),
                    color: helpers.validates.settings.chipStatusColor(
                      order?.status,
                    ),
                  }}
                  className={classes.chip}
                />
              </TableCell>
              <TableCell align="center">
                {constants.settings.HIDE_BILLET_STATUSES.includes(
                  order?.status,
                ) ? (
                  '-'
                ) : (
                  <MenuButton iconButtonSize="small" iconButtonFontSize={18}>
                    <Button
                      size="small"
                      onClick={() => resendNotification(order?.id)}
                    >
                      Reenviar fatura por e-mail
                    </Button>
                  </MenuButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PerfectScrollbar>
  )
}

export default InvoiceTable
