import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  Paper,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import { Code as CodeIcon } from '@material-ui/icons'

import BannerHeadCard from '../BannerHeadCard'
import { BannerCode, SourceCodeDialog } from './components'

import useStyles from './styles'

import theme from 'theme'
import constants from 'constants/index'
import { useFormContext } from 'react-hook-form'

const BannerInstallationCard = ({ bannerToken, isCompiling, build }) => {
  const buildCompleted =
    build?.status === constants.cookies.build.COMPLETED_BUILD_STATUS_ID

  const [openSourceCodeDialog, setOpenSourceCodeDialog] = useState(false)
  const [openBannerCode, setOpenBannerCode] = useState(buildCompleted || false)
  const { formState } = useFormContext()

  const classes = useStyles()

  const isDirty = formState.dirty
  const buildInitiated =
    isCompiling ||
    build?.status === constants.cookies.build.INITIATED_BUILD_STATUS_ID

  const canSeeCode = !buildInitiated && buildCompleted

  const handleOpenSourceDialog = () => setOpenSourceCodeDialog(true)
  const handleCloseOpenSourceDialog = () => setOpenSourceCodeDialog(false)
  const handleClick = () => setOpenBannerCode(!openBannerCode)
  const handleOpenTagManagerDialog = () =>
    window.open(constants.cookies.banner.LINK_DOCUMENTATION_TAG_MANAGER)

  return (
    <>
      <Paper
        variant="outlined"
        id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_4}
      >
        <BannerHeadCard name="Instalação do Banner" stage="05" />
        <Divider />
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
        >
          <Box
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(3)}
            p={2}
            textAlign="justify"
          >
            <Typography color="primary" textAlign="justify">
              Para instalar o banner de cookies, escolha o modo de instalação
              apropriado para o seu site: <strong>HTML</strong> ou{' '}
              <strong>Google Tag Manager</strong>. Em seguida, copie o código
              HTML abaixo e siga as instruções de instalação conforme o modo
              selecionado.
            </Typography>
          </Box>
          <Box className={classes.buttonContainer}>
            <Box className={classes.buttonBox}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleOpenSourceDialog}
              >
                Como instalar com HTML
              </Button>
            </Box>
            <Box className={classes.buttonBox}>
              <Tooltip
                title={
                  (isDirty &&
                    'Salve as alterações de "Personalização do Banner" antes de prosseguir com a geração do código.') ||
                  (!canSeeCode &&
                    'O código do banner só pode ser mostrado após a compilação ter sido realizada com sucesso.')
                }
              >
                <span>
                  <Button
                    startIcon={<CodeIcon size={18} />}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClick}
                    disabled={!canSeeCode || isDirty}
                  >
                    Ver código
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Box className={classes.buttonBox}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleOpenTagManagerDialog}
              >
                Como instalar com Tag Manager
              </Button>
            </Box>
          </Box>
          <Collapse in={openBannerCode}>
            <BannerCode bannerToken={bannerToken} />
          </Collapse>
        </Box>
      </Paper>
      <SourceCodeDialog
        bannerToken={bannerToken}
        open={openSourceCodeDialog}
        setOpen={setOpenSourceCodeDialog}
        actionButton={handleCloseOpenSourceDialog}
      />
    </>
  )
}

BannerInstallationCard.propTypes = {
  bannerToken: PropTypes.string,
}

export default BannerInstallationCard
