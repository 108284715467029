import React from 'react'
import { isEmpty } from 'lodash'
import { Grid, Box, Typography, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { PlusCircle } from 'react-feather'

import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'
import helpers from 'helpers'

import { PlanActionForm, CardType } from '../'

const PlanAction = ({ planActions, questionControl, refresh, isEdit }) => {
  const snackbar = useSnackbar()

  const handleCreatePlan = async () => {
    try {
      await service.dponet.questionControls.createPlan({
        questionControlId: questionControl?.id,
        kind: 'plan_action',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }
  return (
    <Box mt={2}>
      <Grid container spacing={3}>
        {isEmpty(planActions) && (
          <Grid item xs={12}>
            <Alert severity="info" variant="outlined">
              <Typography>
                Nenhum plano de ação foi detectado. Para incluir um, clique no
                botão <strong>Adicionar Plano de Ação</strong>.
              </Typography>
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mb={2} display="flex" justifyContent="space-between">
            <Typography variant="h4">Plano de ação</Typography>
            <Button
              startIcon={<PlusCircle size={20} />}
              variant="contained"
              type="button"
              color="primary"
              disabled={!isEdit || !questionControl?.openWrite}
              onClick={handleCreatePlan}
            >
              ADICIONAR PLANO DE AÇÃO
            </Button>
          </Box>
        </Grid>
        {!questionControl?.openWrite && (
          <Grid item xs={12}>
            <Alert severity="warning">
              <Typography>
                Para editar este plano de ação, é necessário reabri-lo.
              </Typography>
            </Alert>
          </Grid>
        )}
        {planActions?.map((planAction) => (
          <Grid item xs={12}>
            <CardType
              key={planAction?.id}
              isLast={
                planAction?.id === questionControl?.lastPlanActionEvidencyId
              }
              planAction={planAction}
            >
              <PlanActionForm
                planAction={planAction}
                isEdit={isEdit}
                refresh={refresh}
                questionControl={questionControl}
              />
            </CardType>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default PlanAction
